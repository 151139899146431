import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Home, LayoutDashboard, Coins, BookOpen, FileText, AppWindow, Activity, HelpCircle, LogOut, Headphones } from 'lucide-react'
import { initiateTelegramAuth, useAuth, logout } from './telegramAuth'

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

export default function Component() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [openSubMenus, setOpenSubMenus] = useState([1, 2])
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [balance, setBalance] = useState(0)
  const sidebarRef = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const isAuthenticated = useAuth()
  const [isTelegramWebAppAvailable, setIsTelegramWebAppAvailable] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-web-app.js'
    script.async = true
    script.onload = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        setIsTelegramWebAppAvailable(true)
      }
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (isTelegramWebAppAvailable && window.Telegram.WebApp.initDataUnsafe.user && !isAuthenticated) {
      handleAuth()
    }
  }, [isTelegramWebAppAvailable, isAuthenticated])

  useEffect(() => {
    const savedSubMenus = JSON.parse(localStorage.getItem('openSubMenus')) || [1, 2]
    setOpenSubMenus(savedSubMenus)
  }, [])

  useEffect(() => {
    localStorage.setItem('openSubMenus', JSON.stringify(openSubMenus))
  }, [openSubMenus])

  useEffect(() => {
    if (isAuthenticated) {
      fetchBalance()
    }
  }, [isAuthenticated])

  const fetchBalance = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/get-user-balance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`,
        },
        body: JSON.stringify({ userId: 'current' }),
      })
      if (response.ok) {
        const data = await response.json()
        setBalance(data.totalBalance)
      } else {
        const errorData = await response.json()
        if (errorData.error === "Invalid or expired token") {
          handleLogout()
        } else {
          console.error('Failed to fetch balance')
        }
      }
    } catch (error) {
      console.error('Error fetching balance:', error)
    }
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const toggleSubMenu = (index) => {
    setOpenSubMenus(prev => {
      if (prev.includes(index)) {
        return prev.filter(item => item !== index)
      } else {
        return [...prev, index]
      }
    })
  }

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [menuOpen])

  const isActive = (path) => location.pathname === path

  const handleAuth = async () => {
    if (isAuthenticated) {
      handleLogout()
    } else {
      setIsAuthenticating(true)
      try {
        if (isTelegramWebAppAvailable && window.Telegram.WebApp.initDataUnsafe.user) {
          const user = window.Telegram.WebApp.initDataUnsafe
          const initData = window.Telegram.WebApp.initData
          await authenticateWithTelegramWebApp(user, initData)
        } else {
          await initiateTelegramAuth()
        }
      } catch (error) {
        console.error('Authentication failed:', error)
      } finally {
        setIsAuthenticating(false)
      }
    }
  }

  const handleLogout = () => {
    logout()
    setBalance(0)
    history.push('/')
  }

  const authenticateWithTelegramWebApp = async (user, initData) => {
    try {
      const response = await fetch('https://api.pgram.pro/api/auth-with-telegram-web-app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          telegramInitData: user,
          initData
        }),
      })

      if (response.ok) {
        const data = await response.json()
        localStorage.setItem('telegramAuthToken', data.authTokenAuthorization)
        window.location.reload()
      } else {
        throw new Error('Failed to authenticate with Telegram Web App')
      }
    } catch (error) {
      console.error('Error authenticating with Telegram Web App:', error)
      throw error
    }
  }

  return (
    <>
      <nav className="bg-[#27272f] text-white p-4 flex justify-between items-center z-10">
        <button className="text-2xl" onClick={toggleMenu} aria-label="Toggle menu">
          &#9776;
        </button>
        <div className="flex items-center space-x-4">
          {isAuthenticated && (
            <Link to="/balance" className="bg-[#1ec8a5] text-[#383838] px-4 py-2 rounded-full hover:bg-[#19a98c] transition-colors">
              {balance.toFixed(2)} $
            </Link>
          )}
          {isAuthenticated ? (
            (!isTelegramWebAppAvailable || !window.Telegram.WebApp.initDataUnsafe.user) && (
              <button 
                className="bg-[#3299ff] text-white px-4 py-2 rounded-full hover:bg-[#0a85ff] transition-colors flex items-center"
                onClick={handleAuth}
              >
                <LogOut size={20} className="mr-2" />
                Logout
              </button>
            )
          ) : (
            <button 
              className="bg-[#3299ff] text-white px-4 py-2 rounded-full hover:bg-[#0a85ff] transition-colors flex items-center"
              onClick={handleAuth}
              disabled={isAuthenticating}
            >
              {isAuthenticating ? 'Authenticating...' : 'Login with Telegram'}
            </button>
          )}
        </div>
      </nav>

      {menuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-20" aria-hidden="true" onClick={toggleMenu}></div>
      )}

      <div
        ref={sidebarRef}
        className={`fixed top-0 left-0 h-full w-60 bg-[#32333d] text-white p-4 transform transition-transform duration-300 ease-in-out z-30 ${
          menuOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <Link to="/" className={`flex items-center space-x-2 mb-6 ${isActive('/') ? 'text-[#1ec8a5]' : 'text-white hover:text-gray-300'} font-semibold`}>
          <Home size={20} />
          <span>Home</span>
        </Link>

        <div className="space-y-4">
          <div>
            <button
              onClick={() => toggleSubMenu(1)}
              className="w-full flex items-center justify-between text-[#adadb1] hover:text-white transition-colors font-semibold"
            >
              <div className="flex items-center space-x-2">
                <LayoutDashboard size={20} />
                <span>Advertising</span>
              </div>
              <span className={`transform transition-transform duration-200 ${openSubMenus.includes(1) ? 'rotate-180' : ''}`}>
                ▼
              </span>
            </button>
            <div className={`mt-2 ml-6 space-y-2 overflow-hidden transition-all duration-300 ease-in-out ${openSubMenus.includes(1) ? 'max-h-48' : 'max-h-0'}`}>
              <Link to="/campaigns" className={`block ${isActive('/campaigns') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <Activity size={16} />
                <span>Ad Campaigns</span>
              </Link>
              {isAuthenticated && (
                <Link to="/balance" className={`block ${isActive('/balance') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                  <Coins size={16} />
                  <span>Top Up Balance</span>
                </Link>
              )}
              <Link to="/campaignguide" className={`block ${isActive('/campaignguide') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <BookOpen size={16} />
                <span>Instructions</span>
              </Link>
              <Link to="/rules-campaing" className={`block ${isActive('/rules-campaing') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <FileText size={16} />
                <span>Campaign Rules</span>
              </Link>
            </div>
          </div>

          <div>
            <button
              onClick={() => toggleSubMenu(2)}
              className="w-full flex items-center justify-between text-[#adadb1] hover:text-white transition-colors font-semibold"
            >
              <div className="flex items-center space-x-2">
                <AppWindow size={20} />
                <span>Mini Apps</span>
              </div>
              <span className={`transform transition-transform duration-200 ${openSubMenus.includes(2) ? 'rotate-180' : ''}`}>
                ▼
              </span>
            </button>
            <div className={`mt-2 ml-6 space-y-2 overflow-hidden transition-all duration-300 ease-in-out ${openSubMenus.includes(2) ? 'max-h-48' : 'max-h-0'}`}>
              <Link to="/my-mini-apps" className={`block ${isActive('/my-mini-apps') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <AppWindow size={16} />
                <span>My Mini Apps</span>
              </Link>
              <Link to="/monitoring" className={`block ${isActive('/monitoring') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <Activity size={16} />
                <span>Monitoring</span>
              </Link>
              <Link to="/mini-app-instructions" className={`block ${isActive('/mini-app-instructions') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <BookOpen size={16} />
                <span>Instructions</span>
              </Link>
              <Link to="/mini-app-rules" className={`block ${isActive('/mini-app-rules') ? 'text-[#1ec8a5]' : 'text-[#adadb1] hover:text-white'} transition-colors flex items-center space-x-2`}>
                <FileText size={16} />
                <span>Mini App Rules</span>
              </Link>
            </div>
          </div>
        </div>

        <Link to="/faq" className={`flex items-center space-x-2 mt-6 ${isActive('/faq') ? 'text-[#1ec8a5]' : 'text-white hover:text-gray-300'} font-semibold`}>
          <HelpCircle size={20} />
          <span>FAQ</span>
        </Link>

        <a href="https://t.me/pgram_support" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 mt-4 text-white hover:text-gray-300 font-semibold">
          <Headphones size={20} />
          <span>Support</span>
        </a>
      </div>
    </>
  )
}