'use client'

import React, { useState, useEffect } from 'react'
import { X, ChevronDown, HelpCircle } from 'lucide-react'

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

if (!tokenAuthorization) {
  tokenAuthorization = null
}

const predefinedColors = [
  '#3b82f6', // Blue
  '#10b981', // Green
  '#ef4444', // Red
  '#f59e0b', // Yellow
  '#8b5cf6', // Purple
  '#ec4899', // Pink
  '#14b8a6', // Teal
  '#f97316', // Orange
]

export default function CampaignSettings({ campaign, onClose, onSave }) {
  const [campaignName, setCampaignName] = useState('')
  const [campaignHeadline, setCampaignHeadline] = useState('')
  const [adText, setAdText] = useState('')
  const [buttonText, setButtonText] = useState('')
  const [url, setUrl] = useState('')
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [languages, setLanguages] = useState([])
  const [countries, setCountries] = useState([])
  const [selectedPlatforms, setSelectedPlatforms] = useState([])
  const [isPremium, setIsPremium] = useState(false)
  const [frequency, setFrequency] = useState('unique')
  const [customHours, setCustomHours] = useState('')
  const [totalBudget, setTotalBudget] = useState('')
  const [dailyBudget, setDailyBudget] = useState('')
  const [costPerThousand, setCostPerThousand] = useState('')
  const [adTheme, setAdTheme] = useState('light')
  const [buttonColor, setButtonColor] = useState('#3b82f6')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch('/json/languages.json');
        const data = await response.json();
        setLanguages(data);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await fetch('/json/countries.json');
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchLanguages();
    fetchCountries();
  }, []);

  useEffect(() => {
    if (campaign) {
      setCampaignName(campaign.campaignNameAttachment)
      setCampaignHeadline(campaign.campaignHeadline || '')
      setAdText(campaign.adText)
      setButtonText(campaign.buttonText)
      setUrl(campaign.url)
      setTotalBudget(campaign.budget.toString())
      setDailyBudget(campaign.dailyBudget.toString())
      setSelectedLanguages(campaign.languages)
      setSelectedCountries(campaign.countries)
      setSelectedPlatforms(campaign.platforms)
      setIsPremium(campaign.isPremium)
      setFrequency(campaign.frequency)
      setCostPerThousand(campaign.costPerThousand.toString())
      setCustomHours(campaign.customHours ? campaign.customHours.toString() : '')
      setAdTheme(campaign.adTheme || 'light')
      setButtonColor(campaign.buttonColor || '#3b82f6')
    }
  }, [campaign])

  const handleLanguageChange = (e) => {
    const value = e.target.value
    if (value === 'all') {
      setSelectedLanguages(['all'])
    } else if (value && !selectedLanguages.includes(value)) {
      setSelectedLanguages(prevLangs => 
        prevLangs.includes('all') ? [value] : [...prevLangs, value]
      )
    }
  }

  const handleCountryChange = (e) => {
    const value = e.target.value
    if (value === 'all') {
      setSelectedCountries(['all'])
    } else if (value && !selectedCountries.includes(value)) {
      setSelectedCountries(prevCountries => 
        prevCountries.includes('all') ? [value] : [...prevCountries, value]
      )
    }
  }

  const removeLanguage = (code) => {
    setSelectedLanguages(prevLangs => {
      const newLangs = prevLangs.filter(lang => lang !== code)
      return newLangs.length === 0 ? ['all'] : newLangs
    })
  }

  const removeCountry = (code) => {
    setSelectedCountries(prevCountries => {
      const newCountries = prevCountries.filter(country => country !== code)
      return newCountries.length === 0 ? ['all'] : newCountries
    })
  }

  const handleSaveSettings = async () => {
    const newErrors = {
      campaignName: campaignName.trim() === '' ? 'Campaign Name is required' : '',
      campaignHeadline: campaignHeadline.trim() === '' ? 'Campaign Headline is required' : 
                        campaignHeadline.length > 25 ? 'Campaign Headline must be 25 characters or less' : '',
      adText: adText.trim() === '' ? 'Ad Text is required' : 
              adText.length > 80 ? 'Ad Text must be 80 characters or less' : '',
      buttonText: buttonText.trim() === '' ? 'Button Text is required' : '',
      url: url.trim() === '' ? 'URL is required' : '',
      costPerThousand: costPerThousand.trim() === '' ? 'Cost per 1000 views is required' : 
                       parseFloat(costPerThousand) < 0.5 ? 'Cost per 1000 views must be at least $0.5' : '',
      dailyBudget: dailyBudget.trim() === '' ? 'Daily Budget is required' : 
                   parseFloat(dailyBudget) <= 0 ? 'Daily Budget must be greater than 0' : '',
      totalBudget: totalBudget.trim() === '' ? 'Total Budget is required' : 
                   parseFloat(totalBudget) <= 0 ? 'Total Budget must be greater than 0' : '',
      customHours: frequency === 'custom' && customHours.trim() === '' ? 'Custom Hours is required' : 
                   frequency === 'custom' && (parseInt(customHours) < 1 || parseInt(customHours) > 900) ? 'Hours must be between 1 and 900' : '',
    }

    setErrors(newErrors)

    if (Object.values(newErrors).every(error => error === '')) {
      const updatedCampaign = {
        ...campaign,
        campaignNameAttachment: campaignName,
        campaignHeadline,
        adText,
        buttonText,
        url,
        budget: parseFloat(totalBudget),
        dailyBudget: parseFloat(dailyBudget),
        languages: selectedLanguages,
        countries: selectedCountries,
        platforms: selectedPlatforms,
        isPremium,
        frequency,
        customHours: frequency === 'custom' ? parseInt(customHours) : undefined,
        costPerThousand: parseFloat(costPerThousand),
        adTheme,
        buttonColor
      }

      try {
        const response = await fetch('https://api.pgram.pro/api/update-campaign', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${tokenAuthorization}`
          },
          body: JSON.stringify(updatedCampaign)
        })

        const result = await response.json()

        if (response.ok && response.status === 200) {
          console.log('Campaign updated successfully, closing window...')
          onSave(updatedCampaign)
          onClose()
        } else {
          console.log('Failed to update campaign:', result.message)
          setErrors(prev => ({ ...prev, server: result.message || 'Failed to update campaign' }))
        }
      } catch (error) {
        console.error('Error updating campaign:', error)
        setErrors(prev => ({ ...prev, server: 'An error occurred while updating the campaign' }))
      }
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto p-4">
      <div className="bg-[#32333d] text-white p-6 rounded-lg w-full max-w-3xl my-8 max-h-[90vh] overflow-y-auto relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <X className="w-6 h-6" />
        </button>
        <h2 className="text-xl font-bold mb-4 w-[calc(100%-2rem)] line-clamp-3 break-words">Edit Campaign: {campaign?.campaignNameAttachment}</h2>
        <div className="bg-yellow-500 text-black p-4 rounded-md mb-4">
          <p className="font-semibold">Attention!</p>
          <p>Modifying the ad text, button text, or URL will result in your ad campaign being sent for re-moderation.</p>
        </div>
        <div className="space-y-6">
          {/* Campaign Name */}
          <div>
            <input
              type="text"
              placeholder="Campaign Name"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={campaignName}
              onChange={(e) => {
                setCampaignName(e.target.value)
                setErrors(prev => ({ ...prev, campaignName: '' }))
              }}
            />
            {errors.campaignName && <p className="text-red-500 text-sm mt-1">{errors.campaignName}</p>}
          </div>

          {/* Campaign Headline */}
          <div>
            <input
              type="text"
              placeholder="Campaign Headline (max 25 characters)"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              maxLength={25}
              value={campaignHeadline}
              onChange={(e) => {
                setCampaignHeadline(e.target.value)
                setErrors(prev => ({ ...prev, campaignHeadline: '' }))
              }}
            />
            {errors.campaignHeadline && <p className="text-red-500 text-sm mt-1">{errors.campaignHeadline}</p>}
          </div>

          {/* Ad Text */}
          <div>
            <textarea
              placeholder="Ad Text"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              rows={4}
              maxLength={80}
              value={adText}
              onChange={(e) => {
                setAdText(e.target.value)
                setErrors(prev => ({ ...prev, adText: '' }))
              }}
            />
            <p className="text-sm text-gray-400">Characters left: {80 - adText.length}</p>
            {errors.adText && <p className="text-red-500 text-sm mt-1">{errors.adText}</p>}
          </div>

          {/* Button Text */}
          <div>
            <input
              type="text"
              placeholder="Button Text"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              maxLength={15}
              value={buttonText}
              onChange={(e) => {
                setButtonText(e.target.value)
                setErrors(prev => ({ ...prev, buttonText: '' }))
              }}
            />
            {errors.buttonText && <p className="text-red-500 text-sm mt-1">{errors.buttonText}</p>}
          </div>

          {/* URL */}
          <div>
            <input
              type="url"
              placeholder="URL"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value)
                setErrors(prev => ({ ...prev, url: '' }))
              }}
            />
            {errors.url && <p className="text-red-500 text-sm mt-1">{errors.url}</p>}
          </div>

          {/* Language */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Language</h3>
            <div className="relative">
              <select
                className="w-full p-3 bg-[#3d3e4a] rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={handleLanguageChange}
                value=""
              >
                <option value="">Select languages</option>
                {languages.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedLanguages.map((langCode) => {
                const lang = languages.find(l => l.code === langCode)
                return (
                  <div key={langCode} className="bg-blue-500 text-white px-2 py-1 rounded-full flex items-center">
                    {lang ? lang.name : langCode}
                    {langCode !== 'all' && (
                      <button onClick={() => removeLanguage(langCode)} className="ml-2 focus:outline-none">
                        <X className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          {/* Country */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Country</h3>
            <div className="relative">
              <select
                className="w-full p-3 bg-[#3d3e4a] rounded-md appearance-none focus:outline-none  focus:ring-2 focus:ring-blue-500"
                onChange={handleCountryChange}
                value=""
              >
                <option value="">Select countries</option>
                {countries.map((country) =>   (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedCountries.map((countryCode) => {
                const country = countries.find(c => c.code === countryCode)
                return (
                  <div key={countryCode} className="bg-green-500 text-white px-2 py-1 rounded-full flex items-center">
                    {country ? country.name : countryCode}
                    {countryCode !== 'all' && (
                      <button onClick={() => removeCountry(countryCode)} className="ml-2 focus:outline-none">
                        <X className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                )
              })}
            </div>
          </div>

          {/* Platform */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Platform</h3>
            <div className="flex flex-wrap gap-4">
              {['All', 'iOS', 'Android', 'Desktop'].map((platform) => (
                <label key={platform} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedPlatforms.includes(platform.toLowerCase())}
                    onChange={() => {
                      if (platform === 'All') {
                        setSelectedPlatforms(['all'])
                      } else {
                        setSelectedPlatforms((prev) =>
                          prev.includes(platform.toLowerCase())
                            ? prev.filter((p) => p !== platform.toLowerCase())
                            : [...prev.filter((p) => p !== 'all'), platform.toLowerCase()]
                        )
                      }
                    }}
                    className="form-checkbox h-5 w-5 text-blue-500"
                  />
                  <span>{platform}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Telegram Premium */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Telegram Premium</h3>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isPremium}
                onChange={(e) => setIsPremium(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-500"
              />
              <span>Only Telegram Premium</span>
            </label>
          </div>

          {/* Frequency */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Frequency</h3>
            <div className="space-y-2">
              {['Unique', 'Weekly', 'Monthly', 'Custom'].map((option) => (
                <label key={option} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="frequency"
                    value={option.toLowerCase()}
                    checked={frequency === option.toLowerCase()}
                    onChange={(e) => {
                      setFrequency(e.target.value)
                      if (e.target.value !== 'custom') {
                        setErrors(prev => ({ ...prev, customHours: '' }))
                      }
                    }}
                    className="form-radio h-5 w-5 text-blue-500"
                  />
                  <span>{option}</span>
                </label>
              ))}
              {frequency === 'custom' && (
                <div>
                  <input
                    type="number"
                    placeholder="Hours (1-900)"
                    className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    min="1"
                    max="900"
                    value={customHours}
                    onChange={(e) => {
                      setCustomHours(e.target.value)
                      setErrors(prev => ({ ...prev, customHours: '' }))
                    }}
                  />
                  {errors.customHours && <p className="text-red-500 text-sm mt-1">{errors.customHours}</p>}
                </div>
              )}
            </div>
          </div>

          {/* Budget */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Budget</h3>
            <div className="space-y-2">
              <div>
                <input
                  type="number"
                  placeholder="Total Budget ($)"
                  className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={totalBudget}
                  onChange={(e) => {
                    setTotalBudget(e.target.value)
                    setErrors(prev => ({ ...prev, totalBudget: parseFloat(e.target.value) <= 0 ? 'Total Budget must be greater than 0' : '' }))
                  }}
                />
                {errors.totalBudget && <p className="text-red-500 text-sm mt-1">{errors.totalBudget}</p>}
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Daily Budget ($)"
                  className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={dailyBudget}
                  onChange={(e) => {
                    setDailyBudget(e.target.value)
                    setErrors(prev => ({ ...prev, dailyBudget: '' }))
                  }}
                />
                {errors.dailyBudget && <p className="text-red-500 text-sm mt-1">{errors.dailyBudget}</p>}
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Cost per 1000 views ($)"
                  className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  min="0.5"
                  step="0.1"
                  value={costPerThousand}
                  onChange={(e) => {
                    setCostPerThousand(e.target.value)
                    setErrors(prev => ({ ...prev, costPerThousand: '' }))
                  }}
                />
                {errors.costPerThousand && <p className="text-red-500 text-sm mt-1">{errors.costPerThousand}</p>}
              </div>
            </div>
          </div>

          {/* Ad Preview Theme */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Ad Preview Theme</h3>
            <div className="flex space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adTheme"
                  value="light"
                  checked={adTheme === 'light'}
                  onChange={(e) => setAdTheme(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>Light</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adTheme"
                  value="dark"
                  checked={adTheme === 'dark'}
                  onChange={(e) => setAdTheme(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>Dark</span>
              </label>
            </div>
          </div>

          {/* Button Color */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Button Color</h3>
            <div className="flex flex-wrap gap-2">
              {predefinedColors.map((color) => (
                <button
                  key={color}
                  className={`w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${buttonColor === color ? 'ring-2 ring-offset-2 ring-offset-gray-800 ring-white' : ''}`}
                  style={{ backgroundColor: color }}
                  onClick={() => setButtonColor(color)}
                />
              ))}
              <input
                type="color"
                value={buttonColor}
                onChange={(e) => setButtonColor(e.target.value)}
                className="w-8 h-8 rounded-full cursor-pointer"
              />
            </div>
          </div>

          {/* Ad Preview */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Ad Preview</h3>
            <div className={`font-sans max-w-[350px] mx-auto mt-8 space-y-4 ${adTheme === 'light' ? 'bg-white' : 'bg-gray-900'} rounded-2xl shadow-lg overflow-hidden`}>
              <div className="p-4 space-y-2">
                <h2 className={`font-semibold text-base ${adTheme === 'light' ? 'text-gray-900' : 'text-white'}`}>{campaignHeadline || 'Campaign Headline'}</h2>
                <p className={`text-sm ${adTheme === 'light' ? 'text-gray-700' : 'text-gray-300'}`}>{adText || 'Ad text will appear here'}</p>
                <button className="w-full text-white font-semibold py-2 px-4 rounded-lg text-sm" style={{backgroundColor: buttonColor}}>
                  {buttonText || 'Button'}
                </button>
              </div>
            </div>
          </div>

          {/* Save and Cancel Buttons */}
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
            <button
              onClick={handleSaveSettings}
              className="w-full sm:w-1/2 bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded transition duration-200"
            >
              Save Changes
            </button>
            <button
              onClick={onClose}
              className="w-full sm:w-1/2 bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-4 rounded transition duration-200"
            >
              Cancel
            </button>
          </div>

          {errors.server && (
            <p className="text-red-500 text-sm mt-4">{errors.server}</p>
          )}

          <a
            href="https://t.me/GramAdsNetCommunity"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition duration-200"
          >
            <HelpCircle className="w-5 h-5" />
            <span>Get Help</span>
          </a>
        </div>
      </div>
    </div>
  )
}