import React from 'react'

const rules = [
  {
    title: "Prohibited Content",
    items: [
      "Offensive and/or obscene language.",
      "Calls for hatred and/or violence.",
      "Indecent proposals.",
      "Sale of prohibited substances and weapons.",
      "Scams and fraudulent schemes.",
      "Misleading statements.",
      "Content that violates copyright.",
      "Explicit or pornographic material.",
      "Personal attacks or harassment.",
      "Promotion of illegal activities."
    ]
  },
  {
    title: "Advertising Requirements",
    items: [
      "All advertisements must be truthful and not misleading.",
      "Advertisements must comply with all applicable laws and regulations.",
      "Impersonating other brands or individuals is prohibited.",
      "Avoid excessive use of capital letters or punctuation marks.",
      "Consider the cultural characteristics of different regions."
    ]
  },
  {
    title: "Platform Usage",
    items: [
      "Do not attempt to manipulate or abuse platform algorithms.",
      "Respect the integrity of other users' advertising campaigns.",
      "The use of bots or automated systems for boosting is prohibited.",
      "Report any errors or security vulnerabilities to our team."
    ]
  },
  {
    title: "Content Quality",
    items: [
      "Ensure your advertisement is of high quality and relevant to your target audience.",
      "Use appropriate language for your target demographic.",
      "Respect the intellectual property of other individuals and companies."
    ]
  }
]

export default function RulesCampaign() {
  return (
    <div className="min-h-screen bg-[#2e2e3a] text-white p-4 flex flex-col items-center">
      <div className="w-full max-w-3xl space-y-6">
        <h1 className="text-2xl font-bold text-center mb-6">Advertising Campaign Rules</h1>
        {rules.map((section, index) => (
          <div key={index} className="bg-[#3d3d4a] rounded-lg overflow-hidden p-4">
            <h2 className="text-xl font-semibold mb-3">{section.title}</h2>
            <ul className="list-disc pl-5 space-y-2">
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex} className="text-gray-300">{item}</li>
              ))}
            </ul>
          </div>
        ))}
        <div className="bg-[#3d3d4a] rounded-lg overflow-hidden p-4 mt-6">
          <p className="text-gray-300">
            These rules are established to ensure a safe, respectful, and productive environment for all users of our platform. 
            Violating these rules may result in the suspension or termination of your advertising campaign. We reserve the right 
            to update these rules at any time. All advertisers are required to be aware of and comply with these rules. We commit 
            to providing warnings if the rules are changed.
          </p>
        </div>
      </div>
    </div>
  )
}
