import React from 'react'

const rules = [
  {
    title: "General Requirements",
    items: [
      "The application must comply with Telegram rules.",
      "It is prohibited to use the application for distributing malicious content.",
      "The application must be safe and not violate user privacy.",
      "The application owner is responsible for all content displayed in the application, including advertisements."
    ]
  },
  {
    title: "Advertising Integration",
    items: [
      "The frequency of ad displays should be reasonable and not irritate users, within the limit of push advertising display.",
      "It is prohibited to use methods that mislead users regarding advertising content.",
      "It is strictly forbidden to use any methods of artificially inflating views, clicks, or other advertising performance indicators."
    ]
  },
  {
    title: "Content and Functionality",
    items: [
      "The application must provide the stated functionality.",
      "The application content must not violate copyrights or other intellectual property rights.",
      "The application must not contain adult materials unless explicitly stated and access is restricted for minors."
    ]
  },
  {
    title: "Technical Requirements",
    items: [
      "The application must be stable and not cause malfunctions.",
      "Timely updates of the application are mandatory to fix bugs and vulnerabilities.",
    ]
  },
]

export default function RulesMiniApp() {
  return (
    <div className="min-h-screen bg-[#2e2e3a] text-white p-4 flex flex-col items-center">
      <div className="w-full max-w-3xl space-y-6">
        <h1 className="text-2xl font-bold text-center mb-6">Rules for Telegram App Owners</h1>
        {rules.map((section, index) => (
          <div key={index} className="bg-[#3d3d4a] rounded-lg overflow-hidden p-4">
            <h2 className="text-xl font-semibold mb-3">{section.title}</h2>
            <ul className="list-disc pl-5 space-y-2">
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex} className="text-gray-300">{item}</li>
              ))}
            </ul>
          </div>
        ))}
        <div className="bg-[#3d3d4a] rounded-lg overflow-hidden p-4 mt-6">
          <p className="text-gray-300">
            These rules are established to ensure the quality and safety of Telegram applications on our platform. 
            Violation of these rules may result in the removal of the application from the platform or termination of cooperation. 
            Special attention is paid to the fair use of the platform and the prohibition of any forms of artificially inflating indicators. 
            We reserve the right to update these rules at any time. We commit to promptly announcing any changes to the rules.
          </p>
        </div>
      </div>
    </div>
  )
}