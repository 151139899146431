import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/Main';
import CampaignsPage from './pages/CampaignsPage';
import MyMiniApps from './pages/MyMiniApps';
import Dashboard from './pages/Dashboard';
import BalancePage from './pages/BalancePage';
import campaignguide from './pages/campaignguide'; 
import MiniAppsCmpaignsSetting from './components/CampaingsPage/MiniAppsCmpaignsSetting';
import './index.css';
import FAQPage from './pages/FAQ';
import RulesCampaing from './pages/RulesCampaing';
import RulesMiniApp from './pages/RulesMiniApp';
import appmonitoring from './pages/appmonitoring';
import MiniAppsInstructions from './pages/MiniAppsInstructions';
import AuthUserTelegram from './components/AuthUserTelegram';


const App = () => {
    const balance = 0.0; // Определите баланс

    return (
        <Router>
            <Navbar balance={balance} /> {/* Передайте balance */}
            <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/campaigns" component={CampaignsPage} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/balance" component={BalancePage} /> 
                <Route path="/mini-apps" component={MiniAppsCmpaignsSetting} />
                <Route path="/faq" component={FAQPage} />
                <Route path="/rules-campaing" component={RulesCampaing} />
                <Route path="/mini-app-rules" component={RulesMiniApp} />
                <Route path="/my-mini-apps" component={MyMiniApps} />
                <Route path="/monitoring" component={appmonitoring} />
                <Route path="/campaignguide" component={campaignguide} />
                <Route path="/mini-app-instructions" component={MiniAppsInstructions} />
                <Route path="/authentication" component={AuthUserTelegram} />
            </Switch>
        </Router>
    );
};

export default App;
