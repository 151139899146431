import React, { useState } from 'react';

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

if (!tokenAuthorization) {
  tokenAuthorization = null
} 

export default function MiniAppComponent() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    
    if (!formData.get('name')) {
      newErrors.name = 'Mini App Name is required';
    }
    if (!formData.get('telegramLink')) {
      newErrors.telegramLink = 'Telegram Mini App Link is required';
    }
    if (!formData.get('miniAppUrl')) {
      newErrors.miniAppUrl = 'Mini App URL is required';
    }
    if (!file) {
      newErrors.screenshot = 'BotFather Screenshot is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    setApiError('');

    if (!validateForm(formData)) {
      return;
    }

    const formDataObject = Object.fromEntries(formData.entries());
    
    try {
      if (file) {
        formDataObject.screenshot = await convertToBase64(file);
      }

      const response = await fetch('https://api.pgram.pro/api/create-mini-app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ formData: formDataObject }),
      });

      if (response.ok) {
        setIsModalOpen(false);
        alert('Mini App added successfully');
        window.location.reload();
      } else {
        const errorData = await response.json();
        setApiError(errorData.message || 'Failed to create Mini App');
      }
    } catch (error) {
      console.error('Error creating Mini App:', error);
      setApiError('An error occurred while creating the Mini App');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <button
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        onClick={() => setIsModalOpen(true)}
      >
        Add Mini App
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#3e3f4a] p-6 rounded-lg shadow-xl max-w-md w-full">
            <h2 className="text-2xl font-bold text-white mb-4">Add Mini App</h2>
            {apiError && (
              <div className="bg-red-500 text-white p-3 rounded mb-4">
                {apiError}
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-white mb-1">Mini App Name</label>
                <input
                  id="name"
                  name="name"
                  className="w-full px-3 py-2 bg-[#32333d] text-white border border-gray-600 rounded"
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
              </div>
              
              <div>
                <label htmlFor="telegramLink" className="block text-white mb-1 flex items-center">
                  Telegram Mini App Link
                  <span className="ml-2 cursor-help relative group">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-64">
                      The bot creator can get a direct link using the /myapps command in <a href="https://t.me/BotFather" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">BotFather</a>
                    </span>
                  </span>
                </label>
                <input
                  id="telegramLink"
                  name="telegramLink"
                  className="w-full px-3 py-2 bg-[#32333d] text-white border border-gray-600 rounded"
                />
                {errors.telegramLink && <p className="text-red-500 text-xs mt-1">{errors.telegramLink}</p>}
              </div>
              
              <div>
                <label htmlFor="miniAppUrl" className="block text-white mb-1 flex items-center">
                  Mini App URL
                  <span className="ml-2 cursor-help relative group">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-64">
                      The URL of the site that is used in the Telegram web app
                    </span>
                  </span>
                </label>
                <input
                  id="miniAppUrl"
                  name="miniAppUrl"
                  className="w-full px-3 py-2 bg-[#32333d] text-white border border-gray-600 rounded"
                />
                {errors.miniAppUrl && <p className="text-red-500 text-xs mt-1">{errors.miniAppUrl}</p>}
              </div>
              
              <div>
                <label htmlFor="screenshot" className="block text-white mb-1 flex items-center">
                  BotFather Screenshot
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                  </svg>
                </label>
                <input
                  id="screenshot"
                  name="screenshot"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="w-full px-3 py-2 bg-[#32333d] text-white border border-gray-600 rounded file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-500 file:text-white hover:file:bg-violet-600"
                />
                {file && <p className="text-sm text-gray-300 mt-1">{file.name}</p>}
                <p className="text-xs text-gray-400 mt-1">Screenshot from BotFather confirming ownership of Mini App</p>
                {errors.screenshot && <p className="text-red-500 text-xs mt-1">{errors.screenshot}</p>}
              </div>

              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 bg-[#32333d] text-white border border-gray-600 rounded hover:bg-[#3e3f4a] transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-violet-500 text-white rounded hover:bg-violet-600 transition-colors"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}