"use client"

import React, { useState } from 'react'

export default function AdCampaignInstructions() {
  const [selectedImage, setSelectedImage] = useState(null)

  const openImage = (src) => {
    setSelectedImage(src)
  }

  const closeImage = () => {
    setSelectedImage(null)
  }

  const steps = [
    {
      title: "Step 1: Basic Information",
      image: "/step1.png",
      alt: "Basic Information",
      instructions: [
        "Enter a unique Campaign Name",
        "Write a compelling Campaign Headline (max 25 characters)",
        "Craft your Ad Text (keep an eye on the character limit)",
        "Choose an engaging Button Text",
        "Provide the destination URL for your ad"
      ]
    },
    {
      title: "Step 2: Targeting",
      image: "/step2.png",
      alt: "Targeting",
      instructions: [
        "Select target Languages for your campaign",
        "Choose target Countries for ad display",
        "Pick Platforms: iOS, Android, Desktop, or All",
        "Decide if you want to target only Telegram Premium users"
      ]
    },
    {
      title: "Step 3: Budget and Schedule",
      image: "/step3.png",
      alt: "Budget and Schedule",
      instructions: [
        "Set your Total Budget for the campaign",
        "Determine your Daily Budget",
        "Choose the Frequency: Unique, Weekly, Monthly, or Custom",
        "For Custom frequency, specify the interval in days"
      ]
    },
    {
      title: "Step 4: Where to show ads",
      image: "/CampaingWhereShowAds.png",
      alt: "Where to show ads",
      instructions: [
        "At this point you need to select one of 3 options, the default is In all mini appsk",
        "In all mini apps - when you select this option, advertising will be shown in all Mini Apps including yours",
        "In all, except my mini apps - when you select this item, advertising will be shown in all Mini Apps except yours",
        "Only in my mini apps - when you select this item, advertising will be shown exclusively in your Mini Apps. You will also need to select this advertising company in the menu of your Mini App. For advertising displays in your Mini Apps, you are charged $0.05 per 1000 impressions"
      ]
    },
    {
      title: "Step 5: Design and Preview",
      image: "/step4.png",
      alt: "Design and Preview",
      instructions: [
        "Select the Ad Preview Theme: Light or Dark",
        "Choose a Button Color that matches your brand",
        "Review the Ad Preview to ensure everything looks correct",
        "Make any necessary adjustments before submission"
      ]
    }
  ]

  return (
    <div className="min-h-screen bg-[#2e2e3a] text-white p-8">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center">How to Create an Advertising Campaign</h1>

        <div className="space-y-8">
          {steps.map((step, index) => (
            <div key={index} className="bg-[#3d3d4a] border-gray-700 rounded-lg p-4">
              <div className="mb-4">
                <h2 className="text-xl font-bold text-white">{step.title}</h2>
              </div>
              <div className="text-gray-300">
                <div className="mb-4">
                  <img
                    src={step.image}
                    alt={step.alt}
                    className="rounded-lg shadow-lg w-full h-auto cursor-pointer"
                    onClick={() => openImage(step.image)}
                  />
                </div>
                <ul className="list-disc pl-5 space-y-2">
                  {step.instructions.map((instruction, idx) => (
                    <li key={idx}>{instruction}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-[#3d3d4a] border-gray-700 rounded-lg p-4 mt-8 max-w-2xl mx-auto">
          <div className="mb-4">
            <h2 className="text-xl font-bold text-white">Important Reminders</h2>
          </div>
          <div className="text-gray-300">
            <ul className="list-disc pl-5 space-y-2">
              <li>Ensure your ad content complies with all platform rules and guidelines</li>
              <li>Avoid prohibited content such as offensive language, explicit material, or fraudulent schemes</li>
              <li>Be truthful and avoid misleading statements in your advertisements</li>
              <li>Respect the integrity of other users' advertising campaigns</li>
              <li>Consider cultural characteristics of your target regions</li>
            </ul>
          </div>
        </div>

        <div className="mt-8 flex justify-center">
          <a href="https://pgram.pro/campaigns" className="inline-block">
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full">
              Create Your Campaign
            </button>
          </a>
        </div>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeImage}>
          <div className="relative max-w-4xl max-h-full">
            <img src={selectedImage} alt="Enlarged view" className="max-w-full max-h-full rounded-lg" />
          </div>
        </div>
      )}
    </div>
  )
}