'use client'

import React, { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const fetchStatistics = async (range, campaignId) => {
  const tokenAuthorization = localStorage.getItem('telegramAuthToken')
  const response = await fetch(`https://api.pgram.pro/api/statistics-campaign?range=${range}&campaignId=${campaignId}`, {
    headers: {
      'Authorization': `${tokenAuthorization}`
    }
  })
  if (!response.ok) {
    throw new Error('Failed to fetch statistics')
  }
  return await response.json()
}

export default function CampaignStats({ campaign, onClose }) {
  const [timeRange, setTimeRange] = useState('day')
  const [chartData, setChartData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    updateChartData(timeRange)
  }, [timeRange, campaign])

  const updateChartData = async (range) => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchStatistics(range, campaign.id);
      if (range === 'day') {
        // Process data for day range
        const processedData = data.map(item => ({
          ...item,
          date: new Date(2023, 0, 1, item.hour).toISOString(), // Use a dummy date, we only care about hours
        }));
        setChartData(processedData);
      } else {
        setChartData(data);
      }
      setTimeRange(range);
    } catch (err) {
      setError('Failed to fetch statistics. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#32333d] text-white p-6 rounded-lg w-full max-w-3xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold w-[calc(100%-2rem)] line-clamp-3 break-words">
            Statistics for {campaign?.campaignNameAttachment}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>
        <p className="text-gray-400 mb-4">View campaign performance over time</p>
        <div className="flex justify-center space-x-2 mb-4">
          {['day', 'week', 'month', 'quarter'].map((range) => (
            <button
              key={range}
              onClick={() => updateChartData(range)}
              className={`px-4 py-2 rounded-md ${
                timeRange === range
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
              }`}
            >
              {range === 'day' ? 'Day' : range === 'week' ? '7 Days' : range === 'month' ? '30 Days' : '90 Days'}
            </button>
          ))}
        </div>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tick={{ fill: 'white' }}
                tickFormatter={(value) => {
                  if (timeRange === 'day') {
                    return new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                  }
                  return value.split('-').slice(1).join('/');
                }}
              />
              <YAxis yAxisId="left" tick={{ fill: 'white' }} />
              <YAxis yAxisId="right" orientation="right" tick={{ fill: 'white' }} />
              <Tooltip 
                contentStyle={{ backgroundColor: '#32333d', border: 'none' }}
                formatter={(value, name, props) => [value, name]}
                labelFormatter={(label) => {
                  if (timeRange === 'day') {
                    return new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                  }
                  return label;
                }}
              />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="views" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line yAxisId="left" type="monotone" dataKey="clicks" stroke="#82ca9d" />
              <Line yAxisId="right" type="monotone" dataKey="expenses" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}