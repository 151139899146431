import React, { useState } from 'react'
import { ClipboardIcon } from 'lucide-react'

export default function MiniAppsInstructions() {
  const [selectedImage, setSelectedImage] = useState(null)

  const openImage = (src) => {
    setSelectedImage(src)
  }

  const closeImage = () => {
    setSelectedImage(null)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  const navigateToAddMiniApp = () => {
    window.location.href = '/my-mini-apps'
  }

  const steps = [
    {
      title: "Step 1: Add Mini App",
      image: "/image/MiniAppsInstructions/AddMiniApps.png",
      alt: "Add Mini App",
      content: [
        {
          type: "instructions",
          data: [
            "Go to https://pgram.pro/my-mini-apps and click on the 'Add Mini App' button",
            "Enter your Mini App name in the 'Mini App Name' field",
            {
              main: "Enter your Mini App URL in the 'Telegram Mini App Link' field",
              example: "For example:",
              sub: "https://t.me/pgram_pro_bot/pgram",
              note: "The bot creator can get a direct link using the /myapps command in BotFather."
            },
            {
              main: "Enter your Mini App URL in the 'Mini App URL' field",
              example: "For example:",
              sub: "https://pgram.pro",
              note: "The website URL used in the Telegram web application."
            },
            {
              main: "In 'BotFather Screenshot', select an image confirming ownership of the Mini App",
              note: "Enter the /myapps command in BotFather, select your application from the list, and take a screenshot.",
              example: <a href="https://pgram.pro/image/exampleminiappbotfather.png" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Example</a>
            },
          ]
        },
      ]
    },
    {
      title: "Step 2: Get your Mini App's App ID",
      image: "/image/MiniAppsInstructions/AppIDminiApps.png",
      alt: "App ID",
      content: [
        {
          type: "instructions",
          data: [
            "After successfully adding the Mini App, you can get its App ID, which will be necessary for integration in the next step.",
            "Here you can also configure additional parameters for displaying ads in your Mini App and view necessary statistics",
            "'Limit per user' - this is the frequency limit for displaying ads to a user, you can configure it as you see fit, but not less than 3 minutes",
            "'Reward URL' - The endpoint to which a GET request will be sent from our server when the REWARD event occurs (Successful viewing of the advertisement). Part of the [userId] string will be replaced with the user ID in Telegram. Example: https://api.pgram.pro/reward?userId=[userId] In this case, if you take the link from the example above, you must fill in the field with a link: https://api.pgram.pro/reward",
            {
              note: "Reward URL - Optional to fill in, use it if you want to reward the user for watching ads", 
            },
            "'Only own advertising campaigns' - this is the display of exclusively your advertising campaigns in this Mini App",
            {
              note: "Attention! When selecting 'Only own advertising campaigns', only your advertising campaigns will be shown, you need to select the advertising campaigns you want to display",
            },
          ]
        },
      ]
    },
    {
      title: "Step 3: Integration into Mini App",
      content: [
        {
          type: "instructions",
          data: [
            "To connect your mini-application to the PGram advertising network, place the adspgram.js script in the <head> tag using this code:",
          ]
        },
        {
          type: "code",
          data: `<script src="https://pgram.pro/js/adspgram.min.js"></script>`,
          description: "This script needs to be added to the <head> of your HTML document to connect to the PGram advertising network.",
        },
        {
          type: "instructions",
          data: [
            "Initialize PGram SDK"
          ]
        },
        {
          type: "code",
          data: `const AdController =  window.AdsModulePgram;`,
          description: "This line initializes the AdController, which will be used to manage advertising.",
        },
        {
          type: "instructions",
          data: [
            "Show Advertising Push"
          ]
        },
        {
          type: "code",
          data: `AdController.show({ AppId: "your-App-ID" }).then((result) => {
    console.log(result); // Logged result in the console
}).catch((error) => {
    console.log(error); // Logged error in the console
});`,
          description: "This code demonstrates how to show an advertising push notification using AdController. Replace 'your-App-ID' with your actual App ID obtained in Step 2. You can display ads at your discretion, for example, when loading a page or during certain events.",
        },
        {
            type: "instructions",
            data: [
              "Result of the show function in result"
            ]
        },
        {
            type: "code",
            data: `{success: true, message: 'Ad displayed successfully'}
{success: false, message: 'Error: Rate limit exceeded'}`,
            description: "result has the following types: success: true in case of successful ad viewing by the user, in other cases success: false with a description in message:",
        },
      ]
    },
    {
      title: "Step 4: Tests and verification",
      content: [
        {
          type: "instructions",
          data: [
            "Checking App ID status",
          ]
        },
        {
          type: "code",
          data: `AdController.init({ AppId: "your-App-ID" })
.then(result => {
    console.log(result.message, result.success);
});`,
          description: "You can call the init function which returns a message with the status of your Mini App",
        },
        {
          type: "instructions",
          data: [
            "Testing advertising push",
          ]
        },
        {
          type: "code",
          data: `AdController.show({ AppId: "0000" }).then((result) => {
    console.log(result); // Logged result in the console
}).catch((error) => {
    console.log(error); // Logged error in the console
});`,
          description: 'You can call the show function with AppId: "0000" to test the advertising push. The function with AppId: "0000" will trigger the display of a test advertising push, use AppId: "0000" exclusively for testing the advertising push',
        },
        {
          type: "instructions",
          data: [
            "Example of using a Reward URL",
          ]
        },
        {
          type: "code",
          data: `// Route to handle GET request at /reward
app.get('/reward', (req, res) => {
    // Extract userId from query parameters
    const userId = req.query.userId;

    // Check that userid is present
    if (userId) {
        // Log userId
        console.log(userId);
        res.status(200).json({ message: "Received reward request" });
    } else {
        // If userid is missing, return an error
        console.log('Missing userId in request');
        res.status(400).json({ error: 'Missing userId in request' });
    }
});`,
          description: 'This is an example of a Reward URL endpoint handler on a node.js using the express library. Attention! When testing an advertising push with AppID: "0000", the GET request will not be sent.',
        },
      ]
    }
  ]

  return (
    <div className="min-h-screen bg-[#2e2e3a] text-white p-8">
      <h1 className="text-3xl font-bold mb-8 text-center">How to add Telegram Mini App</h1>
      
      <div className="flex flex-col items-center gap-8">
        {steps.map((step, index) => (
          <div key={index} className="bg-[#3d3d4a] border border-gray-700 rounded-lg w-full max-w-2xl">
            <div className="p-4 border-b border-gray-700">
              <h2 className="text-xl font-bold text-white">{step.title}</h2>
            </div>
            <div className="p-4">
              {step.image && (
                <div className="mb-4">
                  <img
                    src={step.image}
                    alt={step.alt}
                    className="rounded-lg shadow-lg w-full h-auto cursor-pointer"
                    onClick={() => openImage(step.image)}
                  />
                </div>
              )}
              {step.content.map((item, idx) => (
                <div key={idx} className="mb-4">
                  {item.type === 'instructions' && (
                    <div className="text-gray-300">
                      <ul className="list-disc pl-5 space-y-2">
                        {item.data.map((instruction, instructionIdx) => (
                          <li key={instructionIdx}>
                            {typeof instruction === 'string' ? (
                              <p>
                                {instruction.split(' ').map((word, i) => (
                                  word.startsWith('http') ? 
                                    <a key={i} href={word} className="text-blue-400 hover:underline">
                                      {word}
                                    </a> : 
                                    ` ${word} `
                                ))}
                              </p>
                            ) : (
                              <div>
                                <p>{instruction.main}</p>
                                <p className="mt-2">{instruction.example}</p>
                                <p className="font-semibold text-green-400">{instruction.sub}</p>
                                <p className="mt-2 ml-4 text-sm text-gray-400 italic">{instruction.note}</p>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {item.type === 'code' && (
                    <div className="relative">
                      <pre className="bg-[#2b2b35] p-4 rounded-lg overflow-x-auto">
                        <code className="text-sm text-gray-300">{item.data}</code>
                      </pre>
                      <button
                        className="absolute top-2 right-2 p-1 rounded-md bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        onClick={() => copyToClipboard(item.data)}
                      >
                        <ClipboardIcon className="h-4 w-4 text-gray-300" />
                      </button>
                      {item.description && (
                        <p className="mt-2 text-sm text-gray-400">{item.description}</p>
                      )}
                      {item.language && (
                        <p className="mt-1 text-xs text-gray-500">Language: {item.language}</p>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="bg-[#3d3d4a] border border-gray-700 rounded-lg mt-8 w-full max-w-2xl mx-auto p-4">
        <h2 className="text-xl font-bold text-white mb-4">Important Reminders</h2>
        <ul className="list-disc pl-5 space-y-2 text-gray-300">
          <li>Always follow Telegram's guidelines for Mini Apps</li>
          <li>Ensure your app is responsive and works well on various devices</li>
          <li>Test thoroughly in the Telegram environment before submission</li>
          <li>Keep user data secure and respect privacy</li>
          <li>Regularly update your Mini App to fix bugs and add new features</li>
        </ul>
      </div>

      <div className="mt-8 text-center">
        <button 
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
          onClick={navigateToAddMiniApp}
        >
          Add Mini App
        </button>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeImage}>
          <div className="relative max-w-4xl max-h-full">
            <img src={selectedImage} alt="Enlarged view" className="max-w-full max-h-full rounded-lg" />
          </div>
        </div>
      )}
    </div>
  )
}