import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, Eye, MousePointer, DollarSign, BarChart2, Trash2, Settings, X, ChevronDown, Info, Copy, Check, AlertTriangle, Plus } from 'lucide-react';
import MiniAppStats from './StatisticsMiniApps';

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

if (!tokenAuthorization) {
  tokenAuthorization = null
}

const Tooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleTooltip = (e) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  return (
    <div className="relative inline-block" ref={tooltipRef}>
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={toggleTooltip}
        className="cursor-pointer"
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm dark:bg-gray-700 bottom-full left-1/2 transform -translate-x-1/2 mb-2 whitespace-nowrap">
          {content}
        </div>
      )}
    </div>
  );
};

const MiniAppItem = ({ app, onDelete, onToggle, onUpdateLimit, onUpdateCampaigns, onUpdateRewardUrl, onUpdateMinimumCPM }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [timeLimit, setTimeLimit] = useState(app.timeLimit || '');
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState(app.advertising_companies_my_list || []);
  const [isCopied, setIsCopied] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [onlyOwnCampaigns, setOnlyOwnCampaigns] = useState(app.my_advertising_campaigns || false);
  const [showRewardSettings, setShowRewardSettings] = useState(false);
  const [rewardUrl, setRewardUrl] = useState(app.url_reward_successful_viewing || '');
  const [showCategorySettings, setShowCategorySettings] = useState(false);
  const [categories, setCategories] = useState([
    "General",
    "Casino/Gambling",
    "Adult Content 18+",
    "Investments and Finance",
    "Crypto",
    "News",
    "Content not for children"
  ]);
  const [selectedCategories, setSelectedCategories] = useState(app.category_campaign || []);
  const [categoryError, setCategoryError] = useState('');
  const [showMinimumCPMSettings, setShowMinimumCPMSettings] = useState(false);
  const [minimumCPM, setMinimumCPM] = useState(app.minimum_CPM || '');
  const [minimumCPMError, setMinimumCPMError] = useState('');

  const isButtonDisabled = app.isActive !== "active" && app.isActive !== "paused";

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/get-campaigns-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setCampaigns(data);

      const preSelectedCampaigns = data.filter(campaign => 
        app.advertising_companies_my_list.includes(campaign.id_campaign)
      ).map(campaign => campaign.id_campaign);
      
      setSelectedCampaigns(preSelectedCampaigns);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const handleToggle = async () => {
    if (isButtonDisabled) return;

    try {
      const response = await fetch('https://api.pgram.pro/api/toggle-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, isActive: app.isActive === "active" ? "paused" : "active" })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onToggle(app.id);
    } catch (error) {
      console.error('Error toggling mini app:', error);
    }
  };

  const handleSaveLimit = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/update-limit-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, timeLimit: timeLimit === '' ? 0 : timeLimit })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onUpdateLimit(app.id, timeLimit);
      setShowSettings(false);
    } catch (error) {
      console.error('Error updating limit:', error);
    }
  };

  const handleCampaignToggle = async (checked) => {
    try {
      const response = await fetch('https://api.pgram.pro/api/toggle-own-campaigns-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, onlyOwnCampaigns: checked })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setOnlyOwnCampaigns(checked);
      setShowCampaigns(checked);
    } catch (error) {
      console.error('Error toggling own campaigns:', error);
    }
  };

  const toggleCampaign = async (campaignId) => {
    const newSelected = selectedCampaigns.includes(campaignId)
      ? selectedCampaigns.filter(id => id !== campaignId)
      : [...selectedCampaigns, campaignId];
    
    try {
      const response = await fetch('https://api.pgram.pro/api/update-campaigns-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, id_campaign: campaignId })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSelectedCampaigns(newSelected);
      onUpdateCampaigns(app.id, newSelected);
    } catch (error) {
      console.error('Error updating campaigns:', error);
    }
  };

  const copyAppId = () => {
    navigator.clipboard.writeText(app.id.toString()).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const handleDelete = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/delete-mini-app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onDelete(app.id);
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error('Error deleting mini app:', error);
    }
  };

  const handleSaveRewardUrl = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/update-reward-successful-viewing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, url_reward_successful_viewing: rewardUrl })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onUpdateRewardUrl(app.id, rewardUrl);
      setShowRewardSettings(false);
    } catch (error) {
      console.error('Error updating reward URL:', error);
    }
  };

  const handleSaveCategories = async () => {
    if (selectedCategories.length === 0) {
      setCategoryError('You must select at least one category.');
      return;
    }

    try {
      const response = await fetch('https://api.pgram.pro/api/update-categories-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, categories: selectedCategories })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onUpdateCampaigns(app.id, selectedCategories);
      setShowCategorySettings(false);
      setCategoryError('');
    } catch (error) {
      console.error('Error updating categories:', error);
    }
  };

  const toggleCategory = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
    setCategoryError('');
  };

  const handleSaveMinimumCPM = async () => {
    if (isNaN(minimumCPM) || minimumCPM < 0) {
      setMinimumCPMError('Minimum CPM must be a number greater than or equal to 0.');
      return;
    }

    try {
      const response = await fetch('https://api.pgram.pro/api/update-minimum-cpm-my-mini-apps', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({ id: app.id, minimum_CPM: minimumCPM === '' ? 0 : minimumCPM })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      onUpdateMinimumCPM(app.id, minimumCPM);
      setShowMinimumCPMSettings(false);
      setMinimumCPMError('');
    } catch (error) {
      console.error('Error updating minimum CPM:', error);
    }
  };

  return (
    <div className="bg-[#3e3f4a] shadow-md rounded-lg p-4 mb-4 text-white">
      {app.isActive === "rejected" ? (
        <div className="bg-red-500 text-white p-2 rounded-t-lg mb-2 flex items-center">
          <AlertTriangle className="mr-2" size={16} />
          Your Mini App has been rejected.
        </div>
      ) : isButtonDisabled && (
        <div className="bg-yellow-500 text-black p-2 rounded-t-lg mb-2 flex items-center">
          <AlertTriangle className="mr-2" size={16} />
          This campaign is under moderation.
        </div>
      )}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2">
        <div className="flex flex-col sm:flex-row items-start sm:items-center mb-2 sm:mb-0">
          <a href={app.link} target="_blank" rel="noopener noreferrer" className="text-lg font-semibold text-blue-400 hover:underline mr-2 mb-1 sm:mb-0">{app.name}</a>
          <div className="flex items-center text-sm text-gray-300">
            App ID: {app.id}
            <Tooltip content="Use this App ID in your Mini App to display an advertisement">
              <Info size={14} className="ml-1 text-gray-400" />
            </Tooltip>
            <button onClick={copyAppId} className="ml-2 text-gray-400 hover:text-white">
              {isCopied ? <Check size={14} /> : <Copy size={14} />}
            </button>
          </div>
        </div>
        <button 
          onClick={handleToggle} 
          className={`p-1 rounded-full border mt-2 sm:mt-0 ${
            isButtonDisabled 
              ? 'border-gray-500 text-gray-500 cursor-not-allowed' 
              : app.isActive === "active" 
                ? 'border-red-500 text-red-500' 
                : 'border-green-500 text-green-500'
          }`}
          disabled={isButtonDisabled}
        >
          {app.isActive === "active" ? <Pause size={14} strokeWidth={2.5} /> : <Play size={14} strokeWidth={2.5} />}
        </button>
      </div>
      <div className="text-sm text-gray-300 mb-2 flex items-center space-x-4">
        <span className="flex items-center"><Eye size={14} className="mr-1" /> {app.views}</span>
        <span  className="flex items-center"><MousePointer size={14} className="mr-1" /> {app.clicks}</span>
        <span className="flex items-center"><DollarSign size={14} className="mr-1" /> {app.earnings.toFixed(2)}</span>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-sm text-gray-300 mr-2">Limit per user: {app.timeLimit || 0} min</span>
          <button onClick={() => setShowSettings(true)} className="text-gray-300 hover:text-white">
            <Settings size={16} />
          </button>
        </div>
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-sm text-gray-300 mr-2">Reward URL</span>
          <button onClick={() => setShowRewardSettings(true)} className="text-gray-300 hover:text-white">
            <Settings size={16} />
          </button>
        </div>
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-sm text-gray-300 mr-2">Campaigns categories</span>
          <button onClick={() => setShowCategorySettings(true)} className="text-gray-300 hover:text-white">
            <Settings size={16} />
          </button>
        </div>
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="text-sm text-gray-300 mr-2">Minimum CPM: ${app.minimum_CPM || 0}</span>
          <button onClick={() => setShowMinimumCPMSettings(true)} className="text-gray-300 hover:text-white">
            <Settings size={16} />
          </button>
        </div>
        <div className="flex space-x-2">
          <button 
            onClick={() => setShowStats(true)} 
            className="bg-[#4a4b55] text-white px-3 py-1 rounded hover:bg-[#5a5b65] flex items-center"
          >
            <BarChart2 size={14} className="mr-1" /> Statistics
          </button>
          <button 
            onClick={() => setShowDeleteConfirm(true)} 
            className="bg-[#4a4b55] text-white px-3 py-1 rounded hover:bg-[#5a5b65] flex items-center"
          >
            <Trash2 size={14} className="mr-1" /> Delete
          </button>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id={`own-campaigns-${app.id}`}
            checked={onlyOwnCampaigns}
            onChange={(e) => handleCampaignToggle(e.target.checked)}
            className="form-checkbox text-blue-500"
          />
          <label
            htmlFor={`own-campaigns-${app.id}`}
            className="text-sm text-gray-300 cursor-pointer"
          >
            Only own advertising campaigns
          </label>
        </div>
        {onlyOwnCampaigns && (
          <div className="relative mt-2">
            <button
              onClick={() => document.getElementById(`dropdown-${app.id}`).classList.toggle('hidden')}
              className="bg-[#4a4b55] text-white px-3 py-1 rounded hover:bg-[#5a5b65] flex items-center"
            >
              Select Campaigns <ChevronDown className="ml-2 h-4 w-4" />
            </button>
            <div id={`dropdown-${app.id}`} className="absolute z-10 hidden mt-2 w-56 rounded-md shadow-lg bg-[#3e3f4a] ring-1 ring-black ring-opacity-5 max-h-60 overflow-y-auto">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {campaigns.map(campaign => (
                  <div key={campaign.id_campaign} className="flex  items-center px-4 py-2 text-sm text-gray-300 hover:bg-[#4a4b65] cursor-pointer" onClick={() => toggleCampaign(campaign.id_campaign)}>
                    <input
                      type="checkbox"
                      checked={selectedCampaigns.includes(campaign.id_campaign)}
                      onChange={() => {}}
                      className="form-checkbox h-4 w-4 text-blue-500 mr-2"
                    />
                    {campaign.campaignName}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {showSettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#3e3f4a] p-6 rounded-lg text-white">
            <h3 className="text-lg font-semibold mb-4">Limit Settings</h3>
            <p className="mb-4">Frequency limit, no more than 1 ad per user in {timeLimit} min.</p>
            <input
              type="number"
              value={timeLimit}
              onChange={(e) => {
                const value = e.target.value;
                setTimeLimit(value === '' ? '' : Number(value));
              }}
              className="border rounded px-2 py-1 mb-4 w-full bg-[#32333d] text-white"
            />
            <p className="text-sm text-gray-300 mb-4">You can set a minimum value and control this yourself.</p>
            <div className="flex justify-end">
              <button onClick={() => setShowSettings(false)} className="bg-[#4a4b55] text-white px-3 py-1 rounded mr-2 hover:bg-[#5a5b65]">Cancel</button>
              <button onClick={handleSaveLimit} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">Save</button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#3e3f4a] p-6 rounded-lg text-white">
            <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
            <p className="mb-4">Are you sure you want to delete this Mini App?</p>
            <div className="flex justify-end">
              <button onClick={() => setShowDeleteConfirm(false)} className="bg-[#4a4b55] text-white px-3 py-1 rounded mr-2 hover:bg-[#5a5b65]">Cancel</button>
              <button onClick={handleDelete} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 flex items-center">
                <Trash2 size={14} className="mr-1" /> Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showRewardSettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#3e3f4a] p-6 rounded-lg text-white max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Reward URL Settings</h3>
            <p className="mb-4 text-sm">
              The endpoint to which a GET request will be sent from our server
              when the REWARD event occurs (Successful viewing of the advertisement).
              Part of the [userId] string will be replaced with the user ID in Telegram.
            </p>
            <p className="mb-4 text-sm">
              Example: https://api.pgram.pro/reward?userId=[userId]
              In this case, if you take the link from the example above, you must fill in the field with a link: https://api.pgram.pro/reward
            </p>
            <input
              type="text"
              value={rewardUrl}
              onChange={(e) => setRewardUrl(e.target.value)}
              className="border rounded px-2 py-1 mb-4 w-full bg-[#32333d] text-white"
              placeholder="Enter reward URL"
            />
            <div className="flex justify-end">
              <button onClick={() => setShowRewardSettings(false)} className="bg-[#4a4b55] text-white px-3 py-1 rounded mr-2 hover:bg-[#5a5b65]">Cancel</button>
              <button onClick={handleSaveRewardUrl} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">Save</button>
            </div>
          </div>
        </div>
      )}

      {showCategorySettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#3e3f4a] p-6 rounded-lg text-white max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Category Settings</h3>
            <div className="mb-4 p-4 bg-yellow-100 text-yellow-800 rounded">
              <p className="font-bold mb-2">Note:</p>
              <p>Removing certain categories may significantly affect your income. You must select at least one category.</p>
              <p className="mt-2">General is the main category for advertising campaigns. It is selected when an advertising campaign does not fit into any other categories.</p>
            </div>
            <div className="mb-4">
              {categories.map(category => (
                <div key={category} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`category-${category}`}
                    checked={selectedCategories.includes(category)}
                    onChange={() => toggleCategory(category)}
                    className="mr-2"
                  />
                  <label htmlFor={`category-${category}`}>{category}</label>
                </div>
              ))}
            </div>
            {categoryError && <p className="text-red-500 mb-4">{categoryError}</p>}
            <div className="flex justify-end">
              <button onClick={() => setShowCategorySettings(false)} className="bg-[#4a4b55] text-white px-3 py-1 rounded mr-2 hover:bg-[#5a5b65]">Cancel</button>
              <button onClick={handleSaveCategories} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">Save</button>
            </div>
          </div>
        </div>
      )}

      {showMinimumCPMSettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#3e3f4a] p-6 rounded-lg text-white max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Minimum CPM Settings</h3>
            <p className="mb-4 text-sm">
              Set the minimum Cost Per Mille (CPM) for this Mini App. This is the minimum amount you're willing to accept per 1000 impressions.
            </p>
            <input
              type="number"
              value={minimumCPM}
              onChange={(e) => {
                const value = e.target.value;
                setMinimumCPM(value === '' ? '' : Number(value));
                setMinimumCPMError('');
              }}
              min="0"
              step="0.01"
              className="border rounded px-2 py-1 mb-4 w-full bg-[#32333d] text-white"
              placeholder="Enter minimum CPM"
            />
            {minimumCPMError && <p className="text-red-500 mb-4">{minimumCPMError}</p>}
            <div className="flex justify-end">
              <button onClick={() => setShowMinimumCPMSettings(false)} className="bg-[#4a4b55] text-white px-3 py-1 rounded mr-2 hover:bg-[#5a5b65]">Cancel</button>
              <button onClick={handleSaveMinimumCPM} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600">Save</button>
            </div>
          </div>
        </div>
      )}

      {showStats && (
        <MiniAppStats miniApp={app} onClose={() => setShowStats(false)} />
      )}
    </div>
  );
};

const MiniAppsList = () => {
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('https://api.pgram.pro/api/my-mini-apps', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${tokenAuthorization}`
          },
          body: JSON.stringify({}) 
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setApps(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching apps:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchApps();
  }, []);

  const handleDelete = (id) => {
    setApps(apps.filter(app => app.id !== id));
  };

  const handleToggle = (id) => {
    setApps(apps.map(app => app.id === id ? { ...app, isActive: app.isActive === "active" ? "paused" : "active" } : app));
  };

  const handleUpdateLimit = (id, newLimit) => {
    setApps(apps.map(app => app.id === id ? { ...app, timeLimit: newLimit } : app));
  };

  const handleUpdateCampaigns = (id, campaigns) => {
    setApps(apps.map(app => app.id === id ? { ...app, advertising_companies_my_list: campaigns } : app));
  };

  const handleUpdateRewardUrl = (id, newUrl) => {
    setApps(apps.map(app => app.id === id ? { ...app, url_reward_successful_viewing: newUrl } : app));
  };

  const handleUpdateMinimumCPM = (id, newCPM) => {
    setApps(apps.map(app => app.id === id ? { ...app, minimum_CPM: newCPM } : app));
  };

  if (isLoading) {
    return <div className="container mx-auto p-4 bg-[#32333d] min-h-screen text-white">Loading...</div>;
  }

  if (error) {
    return (
      <div className="container mx-auto p-4 bg-[#32333d] min-h-screen text-white">
        <h2 className="text-2xl font-bold mb-4">Error</h2>
        <p className="text-re d-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 bg-[#32333d] min-h-screen">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-white">Mini Apps List</h2>
      </div>
      {apps.length === 0 ? (
        <div className="text-white text-center mt-8">
          <p>You don't have any Mini Apps yet. Add them using the "Add Mini App" button above.</p>
        </div>
      ) : (
        apps.map(app => (
          <MiniAppItem
            key={app.id}
            app={app}
            onDelete={handleDelete}
            onToggle={handleToggle}
            onUpdateLimit={handleUpdateLimit}
            onUpdateCampaigns={handleUpdateCampaigns}
            onUpdateRewardUrl={handleUpdateRewardUrl}
            onUpdateMinimumCPM={handleUpdateMinimumCPM}
          />
        ))
      )}
    </div>
  );
};

export default MiniAppsList;