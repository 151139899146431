'use client'

import React, { useState, useEffect } from 'react'
import { ChevronDown, HelpCircle, Plus, X, AlertTriangle } from 'lucide-react'

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

if (!tokenAuthorization) {
  tokenAuthorization = null
}

const predefinedColors = [
  '#3b82f6', // Blue
  '#10b981', // Green
  '#ef4444', // Red
  '#f59e0b', // Yellow
  '#8b5cf6', // Purple
  '#ec4899', // Pink
  '#14b8a6', // Teal
  '#f97316', // Orange
]

const categories = [
  'General',
  'Casino/Gambling',
  'Adult Content 18+',
  'Investments and Finance',
  'Crypto',
  'News',
  'Content not for children'
]

export default function Component() {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [campaignName, setCampaignName] = useState('')
  const [campaignHeadline, setCampaignHeadline] = useState('')
  const [adText, setAdText] = useState('')
  const [buttonText, setButtonText] = useState('')
  const [url, setUrl] = useState('')
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [languages, setLanguages] = useState([])
  const [countries, setCountries] = useState([])
  const [selectedPlatforms, setSelectedPlatforms] = useState(['all'])
  const [isPremium, setIsPremium] = useState(false)
  const [frequency, setFrequency] = useState('custom')
  const [customHours, setCustomHours] = useState('1')
  const [totalBudget, setTotalBudget] = useState('')
  const [dailyBudget, setDailyBudget] = useState('')
  const [costPerThousand, setCostPerThousand] = useState('1.5')
  const [adTheme, setAdTheme] = useState('light')
  const [buttonColor, setButtonColor] = useState('#3b82f6')
  const [adPlacement, setAdPlacement] = useState('all_mini_apps')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [errors, setErrors] = useState({
    campaignName: '',
    campaignHeadline: '',
    adText: '',
    buttonText: '',
    url: '',
    costPerThousand: '',
    dailyBudget: '',
    totalBudget: '',
    customHours: '',
    category: '',
  })
  const [message, setMessage] = useState({ type: '', content: '' })

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch('/json/languages.json');
        const data = await response.json();
        setLanguages(data);
        setSelectedLanguages([data[0]]);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await fetch('/json/countries.json');
        const data = await response.json();
        setCountries(data);
        setSelectedCountries([data[0]]);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchLanguages();
    fetchCountries();
  }, []);

  const sendCampaignData = async (campaignData) => {
    try {
      const response = await fetch('https://api.pgram.pro/api/campaign-create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify(campaignData)
      });

      if (!response.ok) {
        throw new Error('Failed to create campaign');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  const handleAddCampaign = async () => {
    const newErrors = {
      campaignName: campaignName.trim() === '' ? 'Campaign Name is required' : '',
      campaignHeadline: campaignHeadline.trim() === '' ? 'Campaign Headline is required' : 
                        campaignHeadline.length > 25 ? 'Campaign Headline must be 25 characters or less' : '',
      adText: adText.trim() === '' ? 'Ad Text is required' : 
             adText.length > 80 ? 'Ad Text must be 80 characters or less' : '',
      buttonText: buttonText.trim() === '' ? 'Button Text is required' : '',
      url: url.trim() === '' ? 'URL is required' : '',
      costPerThousand: costPerThousand.trim() === '' ? 'Cost per 1000 views is required' : 
                       parseFloat(costPerThousand) < 0.5 ? 'Cost per 1000 views must be at least $0.5' : '',
      dailyBudget: dailyBudget.trim() === '' ? 'Daily Budget is required' : 
                   parseFloat(dailyBudget) <= 0.01 ? 'Daily Budget must be greater than $0.01' : '',
      totalBudget: totalBudget.trim() === '' ? 'Total Budget is required' : 
                   parseFloat(totalBudget) <= 0.01 ? 'Total Budget must be greater than $0.01' : '',
      customHours: frequency === 'custom' && customHours.trim() === '' ? 'Custom Hours is required' : 
                   frequency === 'custom' && (parseInt(customHours) < 0 || parseInt(customHours) > 900) ? 'Hours must be between 0 and 900' : '',
      category: selectedCategory === '' ? 'Category is required' : '',
    }

    setErrors(newErrors)

    if (Object.values(newErrors).every(error => error === '')) {
      const campaignData = {
        campaignName,
        campaignHeadline,
        adText,
        buttonText,
        url,
        languages: selectedLanguages.map(lang => lang.code),
        countries: selectedCountries.map(country => country.code),
        platforms: selectedPlatforms,
        isPremium,
        frequency: {
          type: frequency,
          ...(frequency === 'custom' && { customHours: parseInt(customHours) })
        },
        budget: {
          total: parseFloat(totalBudget),
          daily: parseFloat(dailyBudget),
          costPerThousand: parseFloat(costPerThousand)
        },
        category: selectedCategory,
        adTheme,
        buttonColor,
        adPlacement
      }

      try {
        const response = await fetch('https://api.pgram.pro/api/campaign-create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${tokenAuthorization}`
          },
          body: JSON.stringify(campaignData)
        });

        if (response.ok) {
          window.location.reload();
        } else {
          throw new Error('Failed to create campaign');
        }
      } catch (error) {
        setMessage({ type: 'error', content: 'Failed to add campaign. Please try again.' })
      }
    } else {
      setMessage({ type: 'error', content: 'Please correct the errors in the form.' })
    }
  }

  const handleLanguageChange = (e) => {
    const value = e.target.value
    if (value === 'all') {
      setSelectedLanguages([languages[0]])
    } else if (value && !selectedLanguages.find(lang => lang.code === value)) {
      const language = languages.find(lang => lang.code === value)
      setSelectedLanguages(prevLangs => 
        prevLangs[0].code === 'all' ? [language] : [...prevLangs, language]
      )
    }
  }

  const handleCountryChange = (e) => {
    const value = e.target.value
    if (value === 'all') {
      setSelectedCountries([countries[0]])
    } else if (value && !selectedCountries.find(country => country.code === value)) {
      const country = countries.find(country => country.code === value)
      setSelectedCountries(prevCountries => 
        prevCountries[0].code === 'all' ? [country] : [...prevCountries, country]
      )
    }
  }

  const removeLanguage = (code) => {
    setSelectedLanguages(prevLangs => {
      const newLangs = prevLangs.filter(lang => lang.code !== code)
      return newLangs.length === 0 ? [languages[0]] : newLangs
    })
  }

  const removeCountry = (code) => {
    setSelectedCountries(prevCountries => {
      const newCountries = prevCountries.filter(country => country.code !== code)
      return newCountries.length === 0 ? [countries[0]] : newCountries
    })
  }

  const handleCostChange = (e) => {
    const value = e.target.value
    setCostPerThousand(value)
    setErrors(prev => ({ ...prev, costPerThousand: '' }))
  }

  const handleHoursChange = (e) => {
    const value = e.target.value
    setCustomHours(value)
    setErrors(prev => ({ ...prev, customHours: '' }))
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={`w-full max-w-4xl mx-auto bg-[#32333d] text-white rounded-lg shadow-lg transition-all duration-300 ${isCollapsed ? 'h-20' : ''}`}>
      <div className="p-6">
        <h2 
          className="text-2xl font-bold mb-6 flex items-center justify-between cursor-pointer"
          onClick={toggleCollapse}
          role="button"
          aria-expanded={!isCollapsed}
          tabIndex={0}
        >
          Create Campaign
          {isCollapsed ? <Plus className="w-6 h-6" /> : <X className="w-6 h-6" />}
        </h2>
      </div>

      {!isCollapsed && (
        <div className="p-6 pt-0 space-y-6">
          <div>
            <input
              type="text"
              placeholder="Campaign Name"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={campaignName}
              maxLength={80}
              onChange={(e) => {
                setCampaignName(e.target.value)
                setErrors(prev => ({ ...prev, campaignName: '' }))
              }}
            />
            <p className="text-sm text-gray-400 mt-1">Characters left: {80 - campaignName.length}</p>
            {errors.campaignName && <p className="text-red-500 text-sm mt-1">{errors.campaignName}</p>}
          </div>

          <div>
            <input
              type="text"
              placeholder="Campaign Headline (max 25 characters)"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              maxLength={25}
              value={campaignHeadline}
              onChange={(e) => {
                setCampaignHeadline(e.target.value)
                setErrors(prev => ({ ...prev, campaignHeadline: '' }))
              }}
            />
            {errors.campaignHeadline && <p className="text-red-500 text-sm mt-1">{errors.campaignHeadline}</p>}
          </div>

          <div>
            <textarea
              placeholder="Ad Text"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              rows={4}
              maxLength={80}
              value={adText}
              onChange={(e) => {
                setAdText(e.target.value)
                setErrors(prev => ({ ...prev, adText: '' }))
              }}
            />
            <p className="text-sm text-gray-400">Characters left: {80 - adText.length}</p>
            {errors.adText && <p className="text-red-500 text-sm mt-1">{errors.adText}</p>}
          </div>

          <div>
            <input
              type="text"
              placeholder="Button Text"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              maxLength={15}
              value={buttonText}
              onChange={(e) => {
                setButtonText(e.target.value)
                setErrors(prev => ({ ...prev, buttonText: '' }))
              }}
            />
            <p className="text-sm text-gray-400 mt-1">Characters left: {15 - buttonText.length}</p>
            {errors.buttonText && <p className="text-red-500 text-sm mt-1">{errors.buttonText}</p>}
          </div>

          <div>
            <input
              type="url"
              placeholder="URL"
              className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value)
                setErrors(prev => ({ ...prev, url: '' }))
              }}
            />
            {errors.url && <p className="text-red-500 text-sm mt-1">{errors.url}</p>}
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Language</h3>
            <div className="relative">
              <select
                className="w-full p-3 bg-[#3d3e4a] rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={handleLanguageChange}
                value=""
              >
                <option value="">Select languages</option>
                {languages.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedLanguages.map((lang) => (
                <div key={lang.code} className="bg-blue-500 text-white px-2 py-1 rounded-full flex items-center">
                  
                  {lang.name}
                  {lang.code !== 'all' && (
                    <button onClick={() => removeLanguage(lang.code)} className="ml-2 focus:outline-none">
                      <X className="w-4 h-4" />
                    
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Country</h3>
            <div className="relative">
              <select
                className="w-full p-3 bg-[#3d3e4a] rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={handleCountryChange}
                value=""
              >
                <option value="">Select countries</option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              
              <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedCountries.map((country) => (
                <div key={country.code} className="bg-green-500 text-white px-2 py-1 rounded-full flex items-center">
                  {country.name}
                  {country.code !== 'all' && (
                    <button onClick={() => removeCountry(country.code)} className="ml-2 focus:outline-none">
                      <X className="w-4 h-4" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Platform</h3>
            <div className="flex flex-wrap gap-4">
              {['All', 'iOS', 'Android', 'Desktop'].map((platform) => (
                <label key={platform} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedPlatforms.includes(platform.toLowerCase())}
                    onChange={() => {
                      if (platform === 'All') {
                        setSelectedPlatforms(['all'])
                      } else {
                        setSelectedPlatforms((prev) =>
                          prev.includes(platform.toLowerCase())
                            ? prev.filter((p) => p !== platform.toLowerCase())
                            : [...prev.filter((p) => p !== 'all'), platform.toLowerCase()]
                        )
                      }
                    }}
                    className="form-checkbox h-5 w-5 text-blue-500"
                  />
                  <span>{platform}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Telegram Premium</h3>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isPremium}
                onChange={(e) => setIsPremium(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-500"
              />
              <span>Only Telegram Premium</span>
            </label>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Frequency</h3>
            <div className="space-y-2">
              {['Unique', 'Weekly', 'Monthly', 'Custom'].map((option) => (
                <label key={option} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="frequency"
                    value={option.toLowerCase()}
                    checked={frequency === option.toLowerCase()}
                    onChange={(e) => {
                      setFrequency(e.target.value)
                      if (e.target.value === 'custom') {
                        setCustomHours('1')
                      } else {
                        setErrors(prev => ({ ...prev, customHours: '' }))
                      }
                    }}
                    className="form-radio h-5 w-5 text-blue-500"
                  />
                  <span>{option}</span>
                </label>
              ))}
              {frequency === 'custom' && (
                <div>
                  <input
                    type="number"
                    placeholder="Hours (0-900)"
                    className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    min="0"
                    max="900"
                    value={customHours}
                    onChange={handleHoursChange}
                  />
                  {errors.customHours && <p className="text-red-500 text-sm mt-1">{errors.customHours}</p>}
                </div>
              )}
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Budget</h3>
            <div className="space-y-2">
              <div>
                <input
                  type="number"
                  placeholder="Total Budget ($)"
                  className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={totalBudget}
                  min="0.01"
                  step="0.01"
                  onChange={(e) => {
                    setTotalBudget(e.target.value)
                    setErrors(prev => ({ ...prev, totalBudget: parseFloat(e.target.value) <= 0.01 ? 'Total Budget must be greater than $0.01' : '' }))
                  }}
                />
                {errors.totalBudget && <p className="text-red-500 text-sm mt-1">{errors.totalBudget}</p>}
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Daily Budget ($)"
                  className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={dailyBudget}
                  min="0.01"
                  step="0.01"
                  onChange={(e) => {
                    setDailyBudget(e.target.value)
                    setErrors(prev => ({ ...prev, dailyBudget: parseFloat(e.target.value) <= 0.01 ? 'Daily Budget must be greater than $0.01' : '' }))
                  }}
                />
                {errors.dailyBudget && <p className="text-red-500 text-sm mt-1">{errors.dailyBudget}</p>}
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Cost per 1000 views ($)"
                  className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  min="0.5"
                  step="0.1"
                  value={costPerThousand}
                  onChange={handleCostChange}
                />
                {errors.costPerThousand && <p className="text-red-500 text-sm mt-1">{errors.costPerThousand}</p>}
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Category</h3>
            <div className="relative">
              <select
                className="w-full p-3 bg-[#3d3e4a] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value)
                  setErrors(prev => ({ ...prev, category: '' }))
                }}
              >
                <option value="">Select a category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            {errors.category && <p className="text-red-500 text-sm mt-1">{errors.category}</p>}
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Where to show ads</h3>
            <div className="space-y-2">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adPlacement"
                  value="all_mini_apps"
                  checked={adPlacement === 'all_mini_apps'}
                  onChange={(e) => setAdPlacement(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>In all mini apps</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adPlacement"
                  value="except_my_mini_apps"
                  checked={adPlacement === 'except_my_mini_apps'}
                  onChange={(e) => setAdPlacement(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>In all, except my mini apps</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adPlacement"
                  value="only_my_mini_apps"
                  checked={adPlacement === 'only_my_mini_apps'}
                  onChange={(e) => setAdPlacement(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>Only in my mini apps</span>
              </label>
            </div>
            <div className="mt-2 flex items-center text-yellow-500">
              <AlertTriangle className="w-5 h-5 mr-2" />
              <span className="text-sm">Attention: "Where to show ads" parameters cannot be changed after creating an advertising campaign</span>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Ad Preview Theme</h3>
            <div className="flex space-x-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adTheme"
                  value="light"
                  checked={adTheme === 'light'}
                  onChange={(e) => setAdTheme(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>Light</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="adTheme"
                  value="dark"
                  checked={adTheme === 'dark'}
                  onChange={(e) => setAdTheme(e.target.value)}
                  className="form-radio h-5 w-5 text-blue-500"
                />
                <span>Dark</span>
              </label>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Button Color</h3>
            <div className="flex flex-wrap gap-2">
              {predefinedColors.map((color) => (
                <button
                  key={color}
                  className={`w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${buttonColor === color ? 'ring-2 ring-offset-2 ring-offset-gray-800 ring-white' : ''}`}
                  style={{ backgroundColor: color }}
                  onClick={() => setButtonColor(color)}
                />
              ))}
              <input
                type="color"
                value={buttonColor}
                onChange={(e) => setButtonColor(e.target.value)}
                className="w-8 h-8 rounded-full cursor-pointer"
              />
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Ad Preview</h3>
            <div className={`font-sans max-w-[350px] mx-auto mt-8 space-y-4 ${adTheme === 'light' ? 'bg-white' : 'bg-gray-900'} rounded-2xl shadow-lg overflow-hidden`}>
              <div className="p-4 space-y-2">
                <h2 className={`font-semibold text-base ${adTheme === 'light' ? 'text-gray-900' : 'text-white'}`}>{campaignHeadline || 'Campaign Headline'}</h2>
                <p className={`text-sm ${adTheme === 'light' ? 'text-gray-700' : 'text-gray-300'}`}>{adText || 'Ad text will appear here'}</p>
                <button className="w-full text-white font-semibold py-2 px-4 rounded-lg text-sm" style={{backgroundColor: buttonColor}}>
                  {buttonText || 'Button'}
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-6">
            <button
              onClick={handleAddCampaign}
              className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white font-bold text-lg rounded-lg transition duration-200 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 shadow-lg"
            >
              Add Campaign
            </button>
            <button
              onClick={() => setIsCollapsed(true)}
              className="px-6 py-3 bg-gray-500 hover:bg-gray-600 text-white font-bold text-lg rounded-lg transition duration-200 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 shadow-lg"
            >
              Cancel
            </button>
          </div>

          {message.content && (
            <div className={`p-4 rounded ${message.type === 'success' ? 'bg-green-500' : 'bg-red-500'}`}>
              {message.content}
            </div>
          )}

          <a
            href="https://t.me/pgram_support"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition duration-200"
          >
            <HelpCircle className="w-5 h-5" />
            <span>Get Help</span>
          </a>
        </div>
      )}
    </div>
  )
}