import React, { useEffect } from 'react';
import './CampaignsPage.css';
import AdCampaignCreator from '../components/CampaingsPage/CampaingnsCreate';
import AdCampaignViewer from '../components/CampaingsPage/CampaingnsList';

function AuthCheck({ children }) {
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('telegramAuthToken')
      if (!token) {
        window.location.href = '/authentication' // Redirect to the auth page
      }
    }

    checkAuth()

    window.addEventListener('storage', checkAuth)
    return () => window.removeEventListener('storage', checkAuth)
  }, [])

  return children;
}

const CampaignForm = () => {
  return (
    <AuthCheck>
      <div className="campaings-page">
        <AdCampaignCreator />
        <AdCampaignViewer />
      </div>
    </AuthCheck>
  );
};

export default CampaignForm;