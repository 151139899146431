'use client'

import { Send } from "lucide-react"
import { initiateTelegramAuth, useAuth } from './telegramAuth'
import { useEffect } from 'react'

export default function Component() {
  const isAuthenticated = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = '/' // Redirect to the home page
    }
  }, [isAuthenticated])

  const handleLogin = () => {
    initiateTelegramAuth()
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#32333d]">
      <div className="w-[350px] bg-white/95 backdrop-blur supports-[backdrop-filter]:bg-white/60 rounded-2xl shadow-lg overflow-hidden">
        <div className="p-8">
          <h2 className="text-center text-xl font-semibold text-gray-800 mb-8">
            Please authorize to continue
          </h2>
          <button 
            onClick={handleLogin}
            className="w-full bg-[#2AABEE] hover:bg-[#229ED9] text-white font-bold py-3 px-4 rounded-xl flex items-center justify-center transition duration-300 shadow-md"
          >
            <Send className="mr-3 h-6 w-6" />
            Login with Telegram
          </button>
        </div>
      </div>
    </div>
  )
}