import React from 'react'

const faqs = [
  {
    question: "How does your push advertising exchange work?",
    answer: "Our exchange connects advertisers with Telegram app owners. Advertisers can place push notifications in these apps, reaching their target audience. App owners receive payment for displaying ads."
  },
  {
    question: "What types of advertising do you support?",
    answer: "We specialize in push notifications within Telegram apps."
  },
  {
    question: "How can advertisers start placing ads?",
    answer: "Advertisers can register on our platform, top up their balance, and create an advertising campaign. They can choose the target audience, budget, and many other settings for maximum results."
  },
  {
    question: "How can Telegram app owners join your exchange?",
    answer: "App owners can connect their application on the website. After verification and approval, they will be able to integrate our platform for displaying ads."
  },
  {
    question: "What payment methods do you support?",
    answer: "At this stage, balance top-up is supported through cryptocurrencies. Specific options are available in the balance top-up section."
  },
  {
    question: "How is the cost of advertising calculated?",
    answer: "The cost of advertising depends on several factors, including audience reach, display time, frequency of display, and many other factors. Everything is individual, but we offer the most affordable price on the market."
  },
  {
    question: "How does ad targeting work?",
    answer: "We provide targeting options based on various parameters, including geography, user interests, language, platform, and also allow selecting specific applications. This allows advertisers to precisely tailor their campaigns to the target audience."
  },
  {
    question: "What is the minimum amount to start an advertising campaign?",
    answer: "The minimum amount to launch an advertising campaign is $15. This allows you to conduct a test campaign and evaluate the effectiveness of our platform."
  },
  {
    question: "How often is the statistics of advertising campaigns updated?",
    answer: "Statistics are updated in real-time. Advertisers can track the effectiveness of their campaigns through their personal account."
  },
  {
    question: "How is the quality of advertising materials ensured?",
    answer: "All advertising materials undergo moderation before publication. If you are a Telegram app owner, you don't need to worry about inappropriate ads."
  }
]

export default function FAQPage() {
  return (
    <div className="min-h-screen bg-[#2e2e3a] text-white p-4 flex flex-col items-center">
      <div className="w-full max-w-3xl space-y-4">
        <h1 className="text-2xl font-bold text-center mb-6">FAQ</h1>
        {faqs.map((faq, index) => (
          <div key={index} className="bg-[#3d3d4a] rounded-lg overflow-hidden">
            <div className="p-4">
              <h2 className="font-semibold mb-2">{faq.question}</h2>
              <p className="text-gray-300">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}