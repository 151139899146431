'use client'

import React, { useState, useEffect } from 'react'
import { ChevronDown, ChevronUp, Eye, MousePointer, Play, Pause, BarChart2, MoreVertical, Settings, FileText, Trash2, Smartphone, Filter, Calendar } from 'lucide-react'
import CampaignSettings from './CampaignSettings'
import CampaignStats from './CampaignStatistics'
import * as XLSX from 'xlsx'

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

if (!tokenAuthorization) {
  tokenAuthorization = null
}

export default function CampaignList() {
  const [expandedCampaign, setExpandedCampaign] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [filteredCampaigns, setFilteredCampaigns] = useState([])
  const [openDropdown, setOpenDropdown] = useState(null)
  const [isStatsOpen, setIsStatsOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [campaignToDelete, setCampaignToDelete] = useState(null)

  // Filter State
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' })
  const [budgetFilter, setBudgetFilter] = useState({ min: '', max: '' })
  const [dailyBudgetFilter, setDailyBudgetFilter] = useState({ min: '', max: '' })
  const [viewsFilter, setViewsFilter] = useState({ min: '', max: '' })
  const [clicksFilter, setClicksFilter] = useState({ min: '', max: '' })
  const [statusFilter, setStatusFilter] = useState(['active', 'paused', 'moderation', 'rejected'])

  useEffect(() => {
    fetchCampaigns()
  }, [])

  useEffect(() => {
    applyFilters()
  }, [dateFilter, budgetFilter, dailyBudgetFilter, viewsFilter, clicksFilter, statusFilter, campaigns])

  const fetchCampaigns = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/campaigns', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({})
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      setCampaigns(Array.isArray(data) ? data : [])
      setFilteredCampaigns(Array.isArray(data) ? data : [])
    } catch (error) {
      console.error('Error fetching campaigns:', error)
      setCampaigns([])
      setFilteredCampaigns([])
    }
  }

  const applyFilters = () => {
    if (!Array.isArray(campaigns)) {
      console.error('Campaigns is not an array:', campaigns)
      setFilteredCampaigns([])
      return
    }
    let filtered = campaigns.filter(campaign => {
      const createdAt = new Date(campaign.createdAt)
      return (
        (statusFilter.length === 0 || statusFilter.includes(campaign.status)) &&
        (!dateFilter.start || createdAt >= new Date(dateFilter.start)) &&
        (!dateFilter.end || createdAt <= new Date(dateFilter.end)) &&
        (!budgetFilter.min || campaign.budget >= parseFloat(budgetFilter.min)) &&
        (!budgetFilter.max || campaign.budget <= parseFloat(budgetFilter.max)) &&
        (!dailyBudgetFilter.min || campaign.dailyBudget >= parseFloat(dailyBudgetFilter.min)) &&
        (!dailyBudgetFilter.max || campaign.dailyBudget <= parseFloat(dailyBudgetFilter.max)) &&
        (!viewsFilter.min || campaign.views >= parseInt(viewsFilter.min)) &&
        (!viewsFilter.max || campaign.views <= parseInt(viewsFilter.max)) &&
        (!clicksFilter.min || campaign.clicks >= parseInt(clicksFilter.min)) &&
        (!clicksFilter.max || campaign.clicks <= parseInt(clicksFilter.max))
      )
    })
    setFilteredCampaigns(filtered)
  }

  const toggleCampaign = (id) => {
    setExpandedCampaign(expandedCampaign === id ? null : id)
  }

  const toggleCampaignStatus = async (id) => {
    const campaign = campaigns.find(c => c.id === id)
    if (!campaign || (campaign.status !== 'active' && campaign.status !== 'paused')) {
      return
    }

    const newStatus = campaign.status === 'active' ? 'paused' : 'active'

    try {
      const response = await fetch('https://api.pgram.pro/api/update-campaign-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({
          campaignId: id,
          newStatus: newStatus
        })
      })

      if (!response.ok) {
        throw new Error('Failed to update campaign status')
      }

      setCampaigns(campaigns.map(c => 
        c.id === id ? { ...c, status: newStatus } : c
      ))
    } catch (error) {
      console.error('Error updating campaign status:', error)
    }
  }

  const handleSettings = (campaign) => {
    setSelectedCampaign(campaign)
    setIsSettingsOpen(true)
  }

  const handleReport = (id) => {
    const campaign = campaigns.find(c => c.id === id)
    if (campaign) {
      const worksheet = XLSX.utils.json_to_sheet([campaign])
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Campaign Report")
      XLSX.writeFile(workbook, `Campaign_Report_${id}.xlsx`)
    }
  }

  const handleDelete = (id) => {
    const campaign = campaigns.find(c => c.id === id)
    if (campaign) {
      setCampaignToDelete(campaign)
      setIsDeleteDialogOpen(true)
    }
    setOpenDropdown(null)
  }

  const confirmDelete = async () => {
    if (!campaignToDelete) return

    try {
      const response = await fetch('https://api.pgram.pro/api/delete-campaign', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${tokenAuthorization}`
        },
        body: JSON.stringify({
          campaignId: campaignToDelete.id
        })
      })

      if (!response.ok) {
        throw new Error('Failed to delete campaign')
      }

      setCampaigns(campaigns.map(c => c.id === campaignToDelete.id ? { ...c, status: 'deleted' } : c))
      setFilteredCampaigns(filteredCampaigns.filter(c => c.id !== campaignToDelete.id))
    } catch (error) {
      console.error('Error deleting campaign:', error)
    } finally {
      setIsDeleteDialogOpen(false)
      setCampaignToDelete(null)
    }
  }

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id)
  }

  const openStats = (campaign) => {
    setSelectedCampaign(campaign)
    setIsStatsOpen(true)
  }

  const handleMiniApps = (campaignId) => {
    window.location.href = `/mini-apps/${campaignId}`
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZoneName: 'short'
    };

    return date.toLocaleString(undefined, options);
  }

  const getStatusWarning = (status) => {
    if (status === 'moderation') {
      return 'This campaign is under moderation.';
    } else if (status === 'rejected') {
      return 'This campaign has been rejected.';
    } else if (status === 'deleted') {
      return 'This campaign has been deleted.';
    }
    return null;
  }

  const handleStatusFilterChange = (status) => {
    setStatusFilter(prevFilters =>
      prevFilters.includes(status)
        ? prevFilters.filter(s => s !== status)
        : [...prevFilters, status]
    )
  }

  return (
    <div className="w-full max-w-4xl mx-auto space-y-4 mt-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-white">Ad Campaigns</h1>
        <button 
          className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-gray-200 rounded-md border border-gray-600 flex items-center"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          <Filter className="w-4 h-4 mr-2" />
          Filter
        </button>
      </div>

      {isFilterOpen && (
        <div className="bg-[#32333d] p-4 rounded-lg mb-4">
          <h2 className="text-lg font-semibold mb-2 text-white">Filters</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Status</label>
              <div className="space-y-2">
                {['active', 'paused', 'ended', 'moderation', 'rejected', 'deleted'].map((status) => (
                  <label key={status} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={statusFilter.includes(status)}
                      onChange={() => handleStatusFilterChange(status)}
                      className="mr-2"
                    />
                    <span className="text-sm text-gray-300 capitalize">{status}</span>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Creation Date</label>
              <input
                type="date"
                className="w-full p-2 bg-[#3d3e4a] rounded-md text-white"
                value={dateFilter.start}
                onChange={(e) => setDateFilter({ ...dateFilter, start: e.target.value })}
              />
              <input
                type="date"
                className="w-full mt-2 p-2 bg-[#3d3e4a] rounded-md text-white"
                value={dateFilter.end}
                onChange={(e) => setDateFilter({ ...dateFilter, end: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Total Budget</label>
              <input
                type="number"
                placeholder="Min"
                className="w-full p-2 bg-[#3d3e4a] rounded-md text-white"
                value={budgetFilter.min}
                onChange={(e) => setBudgetFilter({ ...budgetFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-full mt-2 p-2 bg-[#3d3e4a] rounded-md text-white"
                value={budgetFilter.max}
                onChange={(e) => setBudgetFilter({ ...budgetFilter, max: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Daily Budget</label>
              <input
                type="number"
                placeholder="Min"
                className="w-full p-2 bg-[#3d3e4a] rounded-md text-white"
                value={dailyBudgetFilter.min}
                onChange={(e) => setDailyBudgetFilter({ ...dailyBudgetFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-full mt-2 p-2 bg-[#3d3e4a] rounded-md text-white"
                value={dailyBudgetFilter.max}
                onChange={(e) => setDailyBudgetFilter({ ...dailyBudgetFilter, max: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Views</label>
              <input
                type="number"
                placeholder="Min"
                className="w-full p-2 bg-[#3d3e4a] rounded-md text-white"
                value={viewsFilter.min}
                onChange={(e) => setViewsFilter({ ...viewsFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-full mt-2 p-2 bg-[#3d3e4a] rounded-md text-white"
                value={viewsFilter.max}
                onChange={(e) => setViewsFilter({ ...viewsFilter, max: e.target.value })}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">Clicks</label>
              <input
                type="number"
                placeholder="Min"
                className="w-full p-2 bg-[#3d3e4a] rounded-md text-white"
                value={clicksFilter.min}
                onChange={(e) => setClicksFilter({ ...clicksFilter, min: e.target.value })}
              />
              <input
                type="number"
                placeholder="Max"
                className="w-full mt-2  p-2 bg-[#3d3e4a] rounded-md text-white"
                value={clicksFilter.max}
                onChange={(e) => setClicksFilter({ ...clicksFilter, max: e.target.value })}
              />
            </div>
          </div>
        </div>
      )}

      {filteredCampaigns.map((campaign) => (
        <div key={campaign.id}   className="bg-[#32333d] text-white rounded-lg shadow-lg overflow-hidden flex flex-col">
          {getStatusWarning(campaign.status) ? (
            <div className="bg-yellow-500 text-black px-4 py-2  text-sm font-semibold">
              {getStatusWarning(campaign.status)}
            </div>
          ) : campaign.my_mini_apps_sending && (
            <div className="bg-yellow-500 text-black px-4 py-2 text-sm font-semibold">
              Warning: You can use this ad campaign to launch in your mini apps
            </div>
          )}
          <div className="p-4 flex flex-row items-start justify-between">
            <h2 className="text-md font-medium break-words w-5/6">{campaign.campaignNameAttachment}</h2>
            
            <button
              onClick={() => toggleCampaign(campaign.id)}
              className="text-gray-400 hover:text-gray-200 hover:bg-gray-700 p-1 rounded flex-shrink-0"
            >
              {expandedCampaign === campaign.id ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </button>
          </div>
          <div className="px-4 pb-4">
            <div className="flex flex-wrap justify-between items-center mb-2">
              <div className="flex items-center space-x-2 mb-2 sm:mb-0">
                <button
                  onClick={() => toggleCampaignStatus(campaign.id)}
                  className={`p-1 rounded ${
                    campaign.status === 'active' || campaign.status === 'paused'
                      ? 'hover:bg-gray-700'
                      : 'opacity-50 cursor-not-allowed'
                  }`}
                  disabled={campaign.status !== 'active' && campaign.status !== 'paused'}
                  title={campaign.status !== 'active' && campaign.status !== 'paused' ? `Current status: ${campaign.status}` : ''}
                >
                  {campaign.status === 'active' ? (
                    <Pause className="w-4 h-4 text-red-500" />
                  ) : (
                    <Play className="w-4 h-4 text-green-500" />
                  )}
                </button>
                <span className="text-sm text-gray-400 break-words">
                  ${campaign.dailyBudgetSpent} / ${campaign.dailyBudget}
                </span>
              </div>
              <span className="text-sm font-bold break-words">${campaign.totalSpent} / ${campaign.budget}</span>
            </div>
            <div className="w-full bg-gray-700 rounded-full h-2 mb-2">
              <div
                className="bg-blue-500 h-2 rounded-full"
                style={{ width: `${(campaign.totalSpent / campaign.budget) * 100}%` }}
              ></div>
            </div>
            <div className="flex flex-wrap justify-between items-center mt-2 text-sm text-gray-400">
              <span className="flex items-center mb-2 sm:mb-0"><Eye className="w-4 h-4 mr-1" /> {campaign.views}</span>
              <span className="flex items-center"><MousePointer className="w-4 h-4 mr-1" /> {campaign.clicks}</span>
            </div>

            {expandedCampaign === campaign.id && (
              <div className="mt-4 space-y-4">
                {campaign.status !== 'deleted' && (
                  <div className="flex flex-wrap justify-between items-center">
                    <button 
                      onClick={() => openStats(campaign)}
                      className="px-3 py-1 bg-gray-700 hover:bg-gray-600 text-gray-200 rounded-md border border-gray-600 flex items-center mb-2 sm:mb-0"
                    >
                      <BarChart2 className="w-4 h-4 mr-2" />
                      Statistics
                    </button>
                    {campaign.status !== 'deleted' && (
                      <button 
                        onClick={() => handleSettings(campaign)}
                        className="px-3 py-1 bg-gray-700 hover:bg-gray-600 text-gray-200 rounded-md border border-gray-600 flex items-center mb-2 sm:mb-0"
                      >
                        <Settings className="w-4 h-4 mr-2" />
                        Settings
                      </button>
                    )}
                    {campaign.status !== 'deleted' && (
                      <div className="relative">
                        <button
                          onClick={() => toggleDropdown(campaign.id)}
                          className="px-3 py-1 bg-gray-700 hover:bg-gray-600 text-gray-200 rounded-md border border-gray-600 flex items-center"
                        >
                          <MoreVertical className="w-4 h-4" />
                        </button>
                        {openDropdown === campaign.id && (
                          <div className="absolute right-0 mt-2 w-56 bg-gray-800 text-gray-200 border border-gray-700 rounded-md shadow-lg z-10">
                            <button onClick={() => handleMiniApps(campaign.id)} className="w-full text-left px-4 py-2 hover:bg-gray-700 flex items-center">
                              <Smartphone className="mr-2 h-4 w-4" />
                              <span>Mini apps</span>
                            </button>
                            <button onClick={() => handleReport(campaign.id)} className="w-full text-left px-4 py-2 hover:bg-gray-700 flex items-center">
                              <FileText className="mr-2 h-4 w-4" />
                              <span>Get Report</span>
                            </button>
                            <button onClick={() => handleDelete(campaign.id)} className="w-full text-left px-4 py-2 hover:bg-gray-700 flex items-center">
                              <Trash2 className="mr-2 h-4 w-4" />
                              <span>Delete</span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div className="bg-[#3d3e4a] p-4 rounded-md">
                  <h3 className="font-semibold mb-2">Campaign Details</h3>
                  <p className="text-sm text-gray-400 break-words">Status: {campaign.status}</p>
                  <p className="text-sm text-gray-400 break-words">Total Budget: ${campaign.budget}</p>
                  <p className="text-sm text-gray-400 break-words">Campaign Headline: {campaign.campaignHeadline}</p>
                  <p className="text-sm text-gray-400 break-words">Ad Text: {campaign.adText}</p>
                  <p className="text-sm text-gray-400 break-words">Button Text: {campaign.buttonText}</p>
                  <p className="text-sm text-gray-400 break-words">URL: {campaign.url}</p>
                  <p className="text-sm text-gray-400 break-words">Languages: {campaign.languages.join(', ')}</p>
                  <p className="text-sm text-gray-400 break-words">Countries: {campaign.countries.join(', ')}</p>
                  <p className="text-sm text-gray-400 break-words">Platforms: {campaign.platforms.join(', ')}</p>
                  <p className="text-sm text-gray-400 break-words">Telegram Premium: {campaign.isPremium ? 'Yes' : 'No'}</p>
                  <p className="text-sm text-gray-400 break-words">Frequency: {campaign.frequency}</p>
                  {campaign.frequency === 'custom' && (
                    <p className="text-sm text-gray-400 break-words">Custom Hours: {campaign.customHours}</p>
                  )}
                  <p className="text-sm text-gray-400 break-words">Cost per 1000 views: ${campaign.costPerThousand}</p>
                  <p className="text-sm text-gray-400 break-words">Ad Theme: {campaign.adTheme}</p>
                  <p className="text-sm text-gray-400 break-words">Button Color: {campaign.buttonColor}</p>
                  <p className="text-sm text-gray-400 break-words">Display Options in Mini Apps: {campaign.display_options_in_mini_apps === 'all_mini_apps' ? 'All Mini Apps' : campaign.display_options_in_mini_apps.replace(/_/g, ' ')}</p>
                  <p className="text-sm text-gray-400 break-words">Category: {campaign.category}</p>
                  <p className="text-sm text-gray-400 flex items-center mt-2 break-words">
                    <Calendar className="w-4 h-4 mr-2 flex-shrink-0" />
                    Created: {formatDate(campaign.createdAt)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}

      {isStatsOpen && (
        <CampaignStats
          campaign={selectedCampaign}
          onClose={() => setIsStatsOpen(false)}
        />
      )}

      {isSettingsOpen && (
        <CampaignSettings
          campaign={selectedCampaign}
          onClose={() => setIsSettingsOpen(false)}
          onSave={(updatedCampaign) => {
            setCampaigns(campaigns.map(c => c.id === updatedCampaign.id ? updatedCampaign : c))
            setIsSettingsOpen(false)
          }}
        />
      )}

      {isDeleteDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#32333d] p-6 rounded-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4 text-white">Confirm Deletion</h2>
            <p className="mb-6 text-white">
              Are you sure you want to delete the campaign "{campaignToDelete?.campaignNameAttachment}"? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                onClick={() => setIsDeleteDialogOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}