import React, { useEffect } from 'react';
import './CampaignsPage.css';


import AddMiniApps from '../components/MyMiniApps/AddMiniApps';
import ListMiniApps from '../components/MyMiniApps/ListMiniApps';


function AuthCheck({ children }) {
    useEffect(() => {
      const checkAuth = () => {
        const token = localStorage.getItem('telegramAuthToken')
        if (!token) {
          window.location.href = '/authentication' // Redirect to the auth page
        }
      }
  
      checkAuth()
  
      window.addEventListener('storage', checkAuth)
      return () => window.removeEventListener('storage', checkAuth)
    }, [])
  
    return children;
  }

const MyMiniApps = () => {
   
    return (
        <AuthCheck>
            <div class="campaings-page">
            <AddMiniApps />
            <ListMiniApps  />
            </div>
        </AuthCheck>
        
    );
};

export default MyMiniApps;