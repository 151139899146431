import { useState, useEffect } from 'react';

export const initiateTelegramAuth = async () => {
  const botUsername = 'pgram_pro_bot';
  try {
    const response = await fetch('https://api.pgram.pro/api/get-auth-token', {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch auth token');
    }
    const data = await response.json();
    const authToken = data.authToken;
    const authUrl = `https://t.me/${botUsername}?start=auth_${authToken}`;
    window.open(authUrl, '_blank', 'noopener,noreferrer');
    
    // Start checking the auth token status
    checkAuthTokenStatus(authToken);
  } catch (error) {
    console.error('Error initiating Telegram auth:', error);
  }
};

const checkAuthTokenStatus = (token) => {
  const startTime = Date.now();
  const checkInterval = setInterval(async () => {
    try {
      const response = await fetch(`https://api.pgram.pro/api/check-auth-token/${token}`, {
        method: 'GET',
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.authTokenAuthorization) {
          console.log('Authentication successful');
          clearInterval(checkInterval);
          localStorage.setItem('telegramAuthToken', data.authTokenAuthorization);
          window.location.reload(); // Refresh the page after successful authentication
        }
      }
    } catch (error) {
      console.error('Error checking auth token status:', error);
    }
    
    // Stop checking after 10 minutes
    if (Date.now() - startTime > 10 * 60 * 1000) {
      clearInterval(checkInterval);
      console.log('Auth token check timed out');
    }
  }, 5000); // Check every 5 seconds
};

export const checkAuthStatus = () => {
  const token = localStorage.getItem('telegramAuthToken');
  return !!token;
};

export const logout = () => {
  localStorage.removeItem('telegramAuthToken');
  window.location.reload(); // Refresh the page after logout
};

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());

  useEffect(() => {
    const checkAuth = () => {
      setIsAuthenticated(checkAuthStatus());
    };

    window.addEventListener('storage', checkAuth);
    return () => window.removeEventListener('storage', checkAuth);
  }, []);

  return isAuthenticated;
};