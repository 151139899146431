import React, { useState, useEffect } from 'react'

export default function MainPage() {
  const buttonClasses = "py-3 px-6 rounded-md transition duration-100 ease-in-out transform hover:scale-[1.02] active:scale-[0.98] font-semibold text-lg"
  const [notificationText, setNotificationText] = useState('Это текст PUSH-уведомления со светлым фоном. Кнопка blue цвета.')

  const benefits = [
    { icon: '🚀', title: 'Instant Engagement', description: 'Engage your audience in real time by increasing the level of interaction.' },
    { icon: '📈', title: 'Increased Retention', description: 'Push forces users to return to your project.' },
    { icon: '🎯', title: 'Targeted Messaging', description: 'Send personalized content to specific user segments.' },
    { icon: '💰', title: 'Cost-Effective', description: 'High ROI with low implementation and maintenance costs.' },
    { icon: '🔒', title: 'Enhanced Security', description: 'Leverage Telegram\'s robust security for your notifications.' },
    { icon: '🌐', title: 'Global Reach', description: 'Reach as many countries and people of the right age as possible with flexible settings.' }
  ]

  useEffect(() => {
    const messages = [
      'Это текст PUSH-уведомления со светлым фоном. Кнопка blue цвета.',
      'Это текст PUSH-уведомления с темным фоном. Кнопка blue цвета.'
    ]
    let index = 0
    const interval = setInterval(() => {
      setNotificationText(messages[index])
      index = (index + 1) % messages.length
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="min-h-screen bg-[#2e2e3a] text-white p-4 flex flex-col items-center">
      <div className="w-full max-w-4xl space-y-6 sm:space-y-8 px-4">
        <header className="text-center space-y-4 px-4">
          <h1 className="text-4xl sm:text-5xl font-bold leading-tight">Push in Telegram App</h1>
          <p className="text-lg sm:text-xl text-gray-300 max-w-2xl mx-auto">Supercharge your Telegram App engagement or engage users with push!</p>
        </header>

        <div className="bg-[#3d3d4a] rounded-lg p-4 sm:p-6 space-y-4 sm:space-y-6">
          <h2 className="text-2xl sm:text-3xl font-semibold text-center">Why Choose Push Notifications?</h2>
          <p className="text-base sm:text-lg text-center text-gray-300">
            Unlock the full potential of your Telegram App with our in-app Push Notification service. 
            Attract more clients, boost engagement, and drive growth - all this at an affordable price!
          </p>
          
          <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4">
            <a href="https://pgram.pro/campaigns" className={`${buttonClasses} bg-[#1ec8a5] text-[#333] hover:bg-[#1ab897] text-center`}>
              Try it now
            </a>
            <a href="https://pgram.pro/my-mini-apps" className={`${buttonClasses} bg-[#7289da] hover:bg-[#5b6eae] text-center`}>
              Connect App
            </a>
          </div>
        </div>

        <div className="bg-[#3d3d4a] rounded-lg p-4 sm:p-6 space-y-4 sm:space-y-6">
          <h2 className="text-base sm:text-lg text-center text-gray-300">What does it look like? Push notifications appear on top of your Telegram App.</h2>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            {/* Light Theme Notification */}
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden max-w-[350px] w-full">
              <div className="p-4 space-y-2">
                <h2 className="font-semibold text-base text-gray-900">Your title</h2>
                <p className="text-sm text-gray-700">The text of your ad and the color of the button.</p>
                <button className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg text-sm">
                Button text
                </button>
              </div>
            </div>

            {/* Dark Theme Notification */}
            <div className="bg-gray-900 rounded-2xl shadow-lg overflow-hidden max-w-[350px] w-full">
              <div className="p-4 space-y-2">
                <h2 className="font-semibold text-base text-white">Your title</h2>
                <p className="text-sm text-gray-300">The text of your ad and the color of the button.</p>
                <button className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg text-sm">
                Button text
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-[#3d3d4a] rounded-lg p-4 sm:p-6 flex flex-col items-center text-center">
              <div className="text-4xl sm:text-5xl mb-3 sm:mb-4">{benefit.icon}</div>
              <h3 className="text-lg sm:text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-sm sm:text-base text-gray-300">{benefit.description}</p>
            </div>
          ))}
        </div>

        <div className="bg-[#3d3d4a] rounded-lg p-4 sm:p-6 space-y-4 sm:space-y-6">
          <h2 className="text-2xl sm:text-3xl font-semibold text-center">Increase your income from Telegram App with Push</h2>
          <ul className="list-disc list-inside space-y-2 sm:space-y-3 text-base sm:text-lg text-gray-300">
            <li>Sell more ads with new technology that doesn't annoy users.</li>
            <li>You can choose which categories will be advertised in your Telegram App.</li>
            <li>No more full-screen videos that put users off.</li>
            <li>Stay ahead of your competitors in terms of revenue using advanced technology.</li>
            <li>Easy setup and quick connection of your Telegram App.</li>
          </ul>
          <p className="text-center text-lg sm:text-xl font-semibold text-[#1ec8a5]">
            Start boosting your Telegram app's performance today!
          </p>
        </div>
      </div>
    </div>
  )
}