import React, { useState, useEffect } from 'react';
import { Loader2, ExternalLink } from 'lucide-react';

let tokenAuthorization = localStorage.getItem('telegramAuthToken');

if (!tokenAuthorization) {
  tokenAuthorization = null;
}

function AuthCheck({ children }) {
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('telegramAuthToken')
      if (!token) {
        window.location.href = '/authentication' // Redirect to the auth page
      }
    }

    checkAuth()

    window.addEventListener('storage', checkAuth)
    return () => window.removeEventListener('storage', checkAuth)
  }, [])

  return children;
}

export default function Component() {
  const [amount, setAmount] = useState(10);
  const [customAmount, setCustomAmount] = useState('');
  const [networkType, setNetworkType] = useState('TRC-20');
  const [walletAddress, setWalletAddress] = useState('');
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [memo, setMemo] = useState('');
  const [earnedBalance, setEarnedBalance] = useState('0.000000');
  const [advertisingBalance, setAdvertisingBalance] = useState('0.000000');
  const [withdrawalError, setWithdrawalError] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');

  useEffect(() => {
    fetchBalancesAndTransactions();
  }, []);

  const fetchBalancesAndTransactions = async () => {
    try {
      const response = await fetch('https://api.pgram.pro/api/get-user-balance-earned-advertising_balance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenAuthorization
        }
      });
      const data = await response.json();
      setEarnedBalance(data.earned_balance);
      setAdvertisingBalance(data.advertising_balance);
      setTransactions(data.transactions);
    } catch (error) {
      console.error('Error fetching balances and transactions:', error);
    }
  };

  const handleAmountChange = (value) => {
    setAmount(value);
    if (value !== 'custom') {
      setCustomAmount('');
    }
  };

  const isValidAmount = () => {
    if (amount === 'custom') {
      return Number(customAmount) >= 10;
    }
    return amount >= 10;
  };

  const handleDeposit = async () => {
    setIsLoading(true);
    setPaymentLink('');
    try {
      const depositAmount = amount === 'custom' ? Number(customAmount) : amount;
      const response = await fetch('https://api.pgram.pro/api/fill-balance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenAuthorization
        },
        body: JSON.stringify({ amount: depositAmount })
      });
      const data = await response.json();
      if (data.paymentLink) {
        setPaymentLink(data.paymentLink);
        const newWindow = window.open(data.paymentLink, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
          console.warn('Popup blocked or not opened');
        }
      }
    } catch (error) {
      console.error('Error processing deposit:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWithdrawalAmountChange = (e) => {
    const value = e.target.value;
    // Limit to 5 decimal places
    const formattedValue = value.includes('.') ? value.split('.')[0] + '.' + value.split('.')[1].slice(0, 5) : value;
    setWithdrawalAmount(formattedValue);
    if (Number(formattedValue) < 5) {
      setWithdrawalError('Minimum withdrawal amount is $5');
    } else {
      setWithdrawalError('');
    }
  };

  const handleWalletAddressChange = (e) => {
    const value = e.target.value;
    setWalletAddress(value.slice(0, 160)); // Limit to 160 characters
  };

  const handleMemoChange = (e) => {
    const value = e.target.value;
    setMemo(value.slice(0, 100)); // Limit to 100 characters
  };

  const handleWithdraw = async () => {
    if (Number(withdrawalAmount) < 5) {
      setWithdrawalError('Minimum withdrawal amount is $5');
      return;
    }
    try {
      const response = await fetch('https://api.pgram.pro/api/withdraw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenAuthorization
        },
        body: JSON.stringify({
          network_type: networkType,
          wallet_address: walletAddress,
          amount: withdrawalAmount,
          memo_ton: memo
        })
      });
      const data = await response.json();
      if (data.success) {
        window.location.reload();
      } else {
        console.error('Withdrawal failed:', data.message);
        setWithdrawalError(data.message || 'Withdrawal failed. Please try again.');
      }
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      setWithdrawalError('An error occurred. Please try again.');
    }
  };

  const buttonClasses = "py-2 rounded-md transition duration-100 ease-in-out transform hover:scale-[1.02] active:scale-[0.98]";

  const truncateAddress = (address, maxLength = 20) => {
    if (address.length <= maxLength) return address;
    return `${address.slice(0, maxLength / 2)}...${address.slice(-maxLength / 2)}`;
  };

  return (
    <AuthCheck>
      <div className="min-h-screen bg-[#2e2e3a] text-white p-4 flex flex-col items-center">
        <div className="w-full max-w-md space-y-4">
          <div className="bg-[#3d3d4a] rounded-lg p-4 space-y-4">
            <h3 className="text-lg font-semibold text-center">Balances</h3>
            <div className="flex justify-between">
              <span>Advertising balance:</span>
              <span>${advertisingBalance}</span>
            </div>
            <div className="flex justify-between">
              <span>Earned balance:</span>
              <span>${earnedBalance}</span>
            </div>
          </div>

          <div className="bg-[#3d3d4a] rounded-lg p-4 space-y-4">
            <h3 className="text-lg font-semibold text-center">TOP UP BALANCE</h3>
            <div>
              <p className="mb-2">Select the amount</p>
              <div className="grid grid-cols-3 gap-2">
                {[10, 20, 50, 100, 200, 'custom'].map((val) => (
                  <button
                    key={val}
                    className={`${buttonClasses} ${
                      amount === val ? 'bg-[#1ec8a5] text-[#333]' : 'bg-[#4d4d5a]'
                    }`}
                    onClick={() => handleAmountChange(val)}
                  >
                    {val === 'custom' ? 'Custom' : `$${val}`}
                  </button>
                ))}
              </div>
            </div>
            {amount === 'custom' && (
              <input
                type="number"
                className="w-full bg-[#4d4d5a] p-2 rounded-md"
                placeholder="Enter amount (min $10)"
                value={customAmount}
                onChange={(e) => setCustomAmount(e.target.value)}
              />
            )}
            <select
              className="w-full bg-[#4d4d5a] p-2 rounded-md"
              value="Cryptocurrency"
              onChange={() => {}}
            >
              <option value="Cryptocurrency">Cryptocurrency</option>
            </select>
            <button
              className={`${buttonClasses} w-full font-semibold ${
                isValidAmount()
                  ? 'bg-[#1ec8a5] text-[#333] hover:bg-[#1ab897]'
                  : 'bg-[#4d4d5a] text-gray-400 cursor-not-allowed'
              }`}
              disabled={!isValidAmount() || isLoading}
              onClick={handleDeposit}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </div>
              ) : (
                'Deposit'
              )}
            </button>
            {paymentLink && !isLoading && (
              <button
                className={`${buttonClasses} w-full bg-[#4d4d5a] text-white font-semibold hover:bg-[#5d5d6a] flex items-center justify-center`}
                onClick={() => window.open(paymentLink, '_blank')}
              >
                <ExternalLink className="mr-2 h-4 w-4" />
                Open Payment Link
              </button>
            )}
          </div>

          <div className="bg-[#3d3d4a] rounded-lg p-4 space-y-4">
            <h3 className="text-lg font-semibold">Request payout</h3>
            <select
              className="w-full bg-[#4d4d5a] p-2 rounded-md"
              value={networkType}
              onChange={(e) => setNetworkType(e.target.value)}
            >
              <option value="TRC-20">TRC-20</option>
              <option value="TON">TON</option>
            </select>
            <input
              type="text"
              className="w-full bg-[#4d4d5a] p-2 rounded-md"
              placeholder="Wallet address"
              value={walletAddress}
              onChange={handleWalletAddressChange}
              maxLength={160}
            />
            {networkType === 'TON' && (
              <div className="space-y-2">
                <input
                  type="text"
                  className="w-full bg-[#4d4d5a] p-2 rounded-md"
                  placeholder="Memo (optional)"
                  value={memo}
                  onChange={handleMemoChange}
                  maxLength={100}
                />
                <p className="text-xs text-yellow-300">
                  For USDT TON withdrawals to most exchanges, a tag/memo is required. Be sure to include the tag/memo for fund withdrawal. Missing or incorrect tag/memo may result in loss of assets.
                </p>
              </div>
            )}
            <input
              type="number"
              className="w-full bg-[#4d4d5a] p-2 rounded-md"
              placeholder="Withdrawal amount (min $5)"
              value={withdrawalAmount}
              onChange={handleWithdrawalAmountChange}
              step="0.00001"
            />
            {withdrawalError && (
              <p className="text-red-500 text-sm">{withdrawalError}</p>
            )}
            <button 
              className={`${buttonClasses} w-full bg-[#1ec8a5] text-[#333] font-semibold hover:bg-[#1ab897]`}
              onClick={handleWithdraw}
              disabled={Number(withdrawalAmount) < 5}
            >
              Withdraw
            </button>
            <p className="text-sm text-center">Withdrawal fee is $2</p>
            <p className="text-sm text-center text-yellow-300">
              If you want to withdraw your advertising balance, please contact support
              (<a href="https://t.me/pgram_support" className="underline">https://t.me/pgram_support</a>)
            </p>
          </div>

          <div className="bg-[#3d3d4a] rounded-lg p-4">
            <h3 className="text-lg font-semibold mb-4 text-center">Your transactions</h3>
            {transactions.length > 0 ? (
              <div className="space-y-4">
                {transactions.map((transaction, index) => (
                  <div key={index} className="bg-[#4d4d5a] p-3 rounded-md">
                    <div className="flex justify-between items-center">
                      <span className="font-medium">{transaction.type}</span>
                      <span className={`text-sm ${transaction.status === 'Completed' ? 'text-green-400' : 'text-yellow-400'}`}>
                        {transaction.status}
                      </span>
                    </div>
                    <div className="flex justify-between items-center mt-2">
                      <span className="text-sm text-gray-400">{new Date(transaction.createdAt).toLocaleString()}</span>
                      <span className="font-medium">${transaction.amount}</span>
                    </div>
                    {transaction.type === 'Withdraw' && transaction.wallet_address && (
                      <div className="mt-2 text-sm text-gray-400 break-all">
                        To: {truncateAddress(transaction.wallet_address)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center">You have no transactions yet</p>
            )}
          </div>
        </div>
      </div>
    </AuthCheck>
  );
}