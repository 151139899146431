import React, { useState, useEffect } from 'react';

function AuthCheck({ children }) {
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('telegramAuthToken')
      if (!token) {
        window.location.href = '/authentication' // Redirect to the auth page
      }
    }

    checkAuth()

    window.addEventListener('storage', checkAuth)
    return () => window.removeEventListener('storage', checkAuth)
  }, [])

  return children;
}

export default function TelegramStatsDashboard() {
  const [selectedPeriod, setSelectedPeriod] = useState("day");
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('telegramAuthToken');
      if (!token) return;

      let range;
      switch (selectedPeriod) {
        case 'day':
          range = 'day';
          break;
        case '7days':
          range = 'week';
          break;
        case '30days':
          range = 'month';
          break;
        case '90days':
          range = 'quarter';
          break;
        default:
          range = 'day';
      }

      try {
        const response = await fetch(`https://api.pgram.pro/api/statistics-app-monitoring?range=${range}`, {
          headers: {
            'Authorization': `${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch stats');
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, [selectedPeriod]);

  const formatHour = (hour) => {
    return hour !== undefined ? `${hour.toString().padStart(2, '0')}:00` : '';
  };

  return (
    <AuthCheck>
      <div style={{ minHeight: '100vh', padding: '2rem', backgroundColor: "#2e2e3a", color: 'white' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '2rem', textAlign: 'center' }}>Telegram Apps Statistics</h1>

          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '1rem',
            marginBottom: '2rem',
            alignItems: 'center',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%', maxWidth: '400px' }}>
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <button 
                  onClick={() => setSelectedPeriod('day')}
                  style={{
                    padding: '0.5rem',
                    backgroundColor: selectedPeriod === 'day' ? '#3b82f6' : '#4a4a5e',
                    color: 'white',
                    border: 'none',
                    borderRadius: '0.25rem',
                    width: '100%'
                  }}
                >
                  Day
                </button>
                <button 
                  onClick={() => setSelectedPeriod('7days')}
                  style={{
                    padding: '0.5rem',
                    backgroundColor: selectedPeriod === '7days' ? '#3b82f6' : '#4a4a5e',
                    color: 'white',
                    border: 'none',
                    borderRadius: '0.25rem',
                    width: '100%'
                  }}
                >
                  7 Days
                </button>
              </div>

              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <button 
                  onClick={() => setSelectedPeriod('30days')}
                  style={{
                    padding: '0.5rem',
                    backgroundColor: selectedPeriod === '30days' ? '#3b82f6' : '#4a4a5e',
                    color: 'white',
                    border: 'none',
                    borderRadius: '0.25rem',
                    width: '100%'
                  }}
                >
                  30 Days
                </button>
                <button 
                  onClick={() => setSelectedPeriod('90days')}
                  style={{
                    padding: '0.5rem',
                    backgroundColor: selectedPeriod === '90days' ? '#3b82f6' : '#4a4a5e',
                    color: 'white',
                    border: 'none',
                    borderRadius: '0.25rem',
                    width: '100%'
                  }}
                >
                  90 Days
                </button>
              </div>
            </div>
          </div>

          {stats && (
            <>
              <div style={{ 
                backgroundColor: '#3d3d4a', 
                padding: '0.75rem', 
                borderRadius: '0.5rem', 
                marginBottom: '1.5rem',
                display: 'grid',
                gap: '0.75rem',
                gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
                textAlign: 'center'
              }}>
                <div style={{ 
                  backgroundColor: '#1e3a8a', 
                  padding: '0.75rem', 
                  borderRadius: '0.5rem',
                }}>
                  <h3 style={{ color: '#93c5fd', fontSize: '0.875rem', marginBottom: '0.25rem' }}>Views</h3>
                  <p style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#3b82f6', margin: 0 }}>{stats.views_general.toLocaleString()}</p>
                </div>
                <div style={{ 
                  backgroundColor: '#065f46', 
                  padding: '0.75rem', 
                  borderRadius: '0.5rem',
                }}>
                  <h3 style={{ color: '#6ee7b7', fontSize: '0.875rem', marginBottom: '0.25rem' }}>Clicks</h3>
                  <p style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#34d399', margin: 0 }}>{stats.clicks_general.toLocaleString()}</p>
                </div>
                <div style={{ 
                  backgroundColor: '#4B0082', 
                  padding: '0.75rem', 
                  borderRadius: '0.5rem',
                }}>
                  <h3 style={{ color: '#E6E6FA', fontSize: '0.875rem', marginBottom: '0.25rem' }}>Earnings</h3>
                  <p style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#9370DB', margin: 0 }}>${stats.earnings_general.toFixed(2)}</p>
                </div>
              </div>

              <div style={{ backgroundColor: '#3d3d4a', padding: '1rem', borderRadius: '0.5rem', overflowX: 'auto' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>Detailed Statistics</h2>
                <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left', padding: '0.5rem', borderBottom: '1px solid #4a4a5e', width: '40%' }}>
                        {selectedPeriod === 'day' ? 'Hour' : 'Date'}
                      </th>
                      <th style={{ textAlign: 'right', padding: '0.5rem', borderBottom: '1px solid #4a4a5e', width: '20%' }}>Views</th>
                      <th style={{ textAlign: 'right', padding: '0.5rem', borderBottom: '1px solid #4a4a5e', width: '20%' }}>Clicks</th>
                      <th style={{ textAlign: 'right', padding: '0.5rem', borderBottom: '1px solid #4a4a5e', width: '20%' }}>Earnings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.chartData.map((item, index) => (
                      item && (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#4a4a5e' : '#3d3d4a' }}>
                          <td style={{ padding: '0.5rem', whiteSpace: 'nowrap' }}>
                            {selectedPeriod === 'day' ? formatHour(item.hour) : item.date}
                          </td>
                          <td style={{ textAlign: 'right', padding: '0.5rem', whiteSpace: 'nowrap' }}>{item.views.toLocaleString()}</td>
                          <td style={{ textAlign: 'right', padding: '0.5rem', whiteSpace: 'nowrap' }}>{item.clicks.toLocaleString()}</td>
                          <td style={{ textAlign: 'right', padding: '0.5rem', whiteSpace: 'nowrap' }}>${item.earnings.toFixed(2)}</td>
                        </tr>
                      )
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </AuthCheck>
  );
}