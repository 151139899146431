'use client'

import React, { useState, useEffect } from 'react'
import { Play, Pause, ArrowLeft, Eye, MousePointer, Info } from 'lucide-react'

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://api.pgram.pro'

export default function MiniAppsViewer() {
  const [miniApps, setMiniApps] = useState([])
  const [showGuidance, setShowGuidance] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [campaignId, setCampaignId] = useState(null)

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/')
    const id = pathSegments[pathSegments.length - 1]
    if (id && !isNaN(id)) {
      setCampaignId(id)
      fetchMiniApps(id)
    } else {
      setError('Campaign ID not found')
      setIsLoading(false)
    }
  }, [])

  const fetchMiniApps = async (campaignId) => {
    setIsLoading(true)
    setError(null)
    try {
      const tokenAuthorization = localStorage.getItem('telegramAuthToken')
      if (!tokenAuthorization) {
        throw new Error('Authorization token not found')
      }
      
      const response = await fetch(`${API_BASE_URL}/api/campaign/mini-apps?campaignId=${campaignId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenAuthorization
        },
      })
      if (!response.ok) throw new Error('Failed to load mini-apps')
      const data = await response.json()
      setMiniApps(data)
    } catch (error) {
      console.error('Error loading mini-apps:', error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const toggleMiniAppStatus = async (id_mini_app) => {
    try {
      const miniApp = miniApps.find(app => app.id_mini_app === id_mini_app)
      const newStatus = !miniApp.approved_for_use_mini_app
      
      const tokenAuthorization = localStorage.getItem('telegramAuthToken')
      if (!tokenAuthorization) {
        throw new Error('Authorization token not found')
      }

      const response = await fetch(`${API_BASE_URL}/api/campaign/mini-apps/approve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': tokenAuthorization
        },
        body: JSON.stringify({ campaignId, id_mini_app })
      })

      if (!response.ok) throw new Error('Failed to update mini-app status')

      setMiniApps(miniApps.map(app => 
        app.id_mini_app === id_mini_app 
          ? { ...app, approved_for_use_mini_app: newStatus } 
          : app
      ))
    } catch (error) {
      console.error('Error updating mini-app status:', error)
      setError(error.message)
    }
  }

  const handleNavigateBack = () => {
    window.location.href = '/campaigns'
  }

  if (isLoading) return <div className="w-full max-w-4xl mx-auto p-4">Loading...</div>
  if (error) return <div className="w-full max-w-4xl mx-auto p-4 text-red-500">Error: {error}</div>

  return (
    <div className="w-full max-w-4xl mx-auto space-y-4 p-4">
      <div className="bg-[#32333d] text-white rounded-lg shadow-lg overflow-hidden p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <button 
              onClick={handleNavigateBack}
              className="mr-4 text-gray-400 hover:text-white bg-transparent border-none cursor-pointer"
            >
              <ArrowLeft className="w-6 h-6" />
              <span className="sr-only">Back to campaigns</span>
            </button>
            <div>
              <h2 className="text-xl font-bold">Mini Apps</h2>
              <p className="text-sm text-gray-400">Campaign ID: {campaignId}</p>
            </div>
          </div>
          <button
            onClick={() => setShowGuidance(!showGuidance)}
            className="text-gray-400 hover:text-white bg-transparent border-none cursor-pointer"
          >
            <Info className="w-6 h-6" />
            <span className="sr-only">Toggle guidance</span>
          </button>
        </div>

        {showGuidance && (
          <div className="bg-[#3d3e4a] p-4 rounded-md mb-4 text-sm">
            <h3 className="font-semibold mb-2">Welcome to Mini Apps Dashboard</h3>
            <p className="mb-2">
              Here, you can monitor and optimize your ad performance across various Mini Apps:
            </p>
            <ul className="list-disc list-inside space-y-1 mb-2">
              <li>Track views and clicks for each Mini App</li>
              <li>Toggle ad visibility with the play/pause button</li>
              <li>Identify top-performing apps to focus your ad spend</li>
            </ul>
            <p>
              Use these insights to refine your strategy and maximize your campaign's impact.
            </p>
          </div>
        )}

        {miniApps.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {miniApps.map((app) => (
              <div key={app.id_mini_app} className="bg-[#3d3e4a] p-4 rounded-md">
                <div className="flex justify-between items-center mb-2">
                  <a href={app.url_mini_app} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    {app.name_mini_app}
                  </a>
                  <button
                    onClick={() => toggleMiniAppStatus(app.id_mini_app)}
                    className="p-1 hover:bg-gray-700 rounded bg-transparent border-none cursor-pointer"
                  >
                    {app.approved_for_use_mini_app ? (
                      <Pause className="w-4 h-4 text-red-500" />
                    ) : (
                      <Play className="w-4 h-4 text-green-500" />
                    )}
                  </button>
                </div>
                <div className="flex justify-between text-sm text-gray-400">
                  <span className="flex items-center"><Eye className="w-4 h-4 mr-1" /> {app.viewsCount}</span>
                  <span className="flex items-center"><MousePointer className="w-4 h-4 mr-1" /> {app.clicksCount}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-400">No mini-apps available</div>
        )}
      </div>
    </div>
  )
}